import React from 'react';
import Alert from "react-bootstrap/Alert";

function AlertDismissible(props) {
    return ( 
        <Alert variant="danger" onClose={props.onClose} dismissible>
            <p className={"alert-message"}>
                {props.alertMessage}
            </p>
        </Alert>
    )
}

export default AlertDismissible;