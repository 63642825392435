import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './NavBar.css'
import Button from '../Button/Button';
import Dropdown from 'react-multilevel-dropdown';
import { SUPER_ADMIN, ADMIN } from '../../constants/userConstants';
import { ACCESS_TOKEN_NAME } from '../../constants/apiConstants';

import nas_logo from './../../resources/NAS_Logo.svg'



const NavBar = ({role}) => {
  if (role === SUPER_ADMIN) {
    return <AdminRole />;
  } else if (role === ADMIN) {
    return <EmployerRole />;
  } else {
    return <PreLoggedIn />;
  };
}

const handleLogout = (e) => {
  e.preventDefault();
  localStorage.removeItem(ACCESS_TOKEN_NAME)
  window.location.reload(true);
}

const handleProfile = (e) => {
  e.preventDefault();
  window.location.href='/#/my-profile';
}

function AdminRole(props){
  return (
    <div style={{borderBottom:"1px solid rgb(212, 212, 212)"}}>
      <Navbar bg="white" variant="light" >
      <Navbar.Brand href="/#/my-dashboard">
          <img
              alt=""
              src={nas_logo}
              width="200"
              className="d-inline-block align-middle align-center navbar"
            /> 
        </Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link href="/#/my-dashboard">Home</Nav.Link>
          <Nav.Link href="/#/job-application-submission">Post a job listing</Nav.Link>
          <Nav.Link href="/#/add-admin">Add admin</Nav.Link>
          <Nav.Link href="/#/add-employer">Add employer</Nav.Link>
          <Nav.Link href="/#/employers">Employers</Nav.Link>
          <Nav.Link href="/#/consenting-candidates">Jobseekers</Nav.Link>
          <Nav.Link href="/#/report">Analytics report</Nav.Link>

          <Dropdown className="resources-dropdown" title='Resources'>
            <Dropdown.Item className="dropdown-item item-hover">
              Starter pack
              <Dropdown.Submenu position='right'>
                <Dropdown.Item className="item-hover">
                  <a className="dropdown-link" href="https://www.autism.org.uk/what-we-do/employment/autism-at-work/employer-welcome-pack" target="_blank" rel="noopener noreferrer">
                    Employer welcome pack
                  </a>
                </Dropdown.Item>
                <Dropdown.Item className="item-hover">
                  <a className="dropdown-link" href="https://www.autism.org.uk/what-we-do/employment/autism-at-work/induction-pack-checklist" target="_blank" rel="noopener noreferrer">
                    Induction pack checklist
                  </a>
                </Dropdown.Item>
              </Dropdown.Submenu>
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item item-hover">
              Job listing advice
              <Dropdown.Submenu position='right'>
                <Dropdown.Item className="item-hover">
                  <a className="dropdown-link" href="https://www.autism.org.uk/what-we-do/employment/autism-at-work/job-advert-guidance" target="_blank" rel="noopener noreferrer">
                  Employer: Job advert guidance
                  </a>
                </Dropdown.Item>
                <Dropdown.Item className="item-hover">
                  <a className="dropdown-link" href="https://www.autism.org.uk/what-we-do/employment/autism-at-work/job-description-top-tips" target="_blank" rel="noopener noreferrer">
                  Employer: Job description top tips
                  </a>
                </Dropdown.Item>
                <Dropdown.Item className="item-hover">
                  <a className="dropdown-link" href="https://www.autism.org.uk/advice-and-guidance/topics/employment/seeking-work" target="_blank" rel="noopener noreferrer">
                  Job Seekers: Advice and Guidance on Seeking Work
                  </a>
                </Dropdown.Item>
              </Dropdown.Submenu>
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item item-hover">
              Programmes
              <Dropdown.Submenu position='right'>
                <Dropdown.Item className="item-hover">
                  <a className="dropdown-link" href="https://www.autism.org.uk/what-we-do/employment/autism-work-programme" target="_blank" rel="noopener noreferrer">
                    Autism at work
                  </a>
                </Dropdown.Item>
                <Dropdown.Item className="item-hover">
                  <a className="dropdown-link" href="https://www.autism.org.uk/what-we-do/employment/hb-cares-programme" target="_blank" rel="noopener noreferrer">
                    Hospitality/HB cares
                  </a>
                </Dropdown.Item>
              </Dropdown.Submenu>
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item item-hover">
              Recruitment tips
              <Dropdown.Submenu position='right'>
                <Dropdown.Item className="item-hover">
                  <a className="dropdown-link" href="https://www.autism.org.uk/advice-and-guidance/topics/employment/employing-autistic-people/employers" target="_blank" rel="noopener noreferrer">
                    Recruitment top tips for employers
                  </a>
                </Dropdown.Item>
                <Dropdown.Item className="item-hover">
                  <a className="dropdown-link" href="https://www.autism.org.uk/what-we-do/employment/autism-at-work/employers-fact-sheet" target="_blank" rel="noopener noreferrer">
                    Interview fact sheet for employers
                  </a>
                </Dropdown.Item>
              </Dropdown.Submenu>
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item item-hover">
              Videos
              <Dropdown.Submenu position='right'>
                <Dropdown.Item className="item-hover">
                  <a className="dropdown-link" href="https://www.facebook.com/watch/?v=324172525451503" target="_blank" rel="noopener noreferrer">
                  For Employers and Job Seekers: Interviews with autism professionals
                  </a>
                </Dropdown.Item>
                <Dropdown.Item className="item-hover">
                  <a className="dropdown-link" href="https://www.autism.org.uk/advice-and-guidance/professional-practice/employment-adjustments-tips" target="_blank" rel="noopener noreferrer">
                  For Employers and Job Seekers: Reasonable adjustments
                  </a>
                </Dropdown.Item>
                <Dropdown.Item className="item-hover">
                  <a className="dropdown-link" href="https://www.autism.org.uk/advice-and-guidance/professional-practice/recruitment-interviews" target="_blank" rel="noopener noreferrer">
                  For Employers and Job Seekers: Recruitment and interviews
                  </a>
                </Dropdown.Item>
              </Dropdown.Submenu>
            </Dropdown.Item>
            <Dropdown.Item className="item-hover">
              <a className="dropdown-link" href="https://www.autism.org.uk/what-we-do/professional-development/training-and-conferences/employment/finding-employment" target="_blank" rel="noopener noreferrer">
                "Finding employment" online module 
              </a>
            </Dropdown.Item>
          </Dropdown>
          <Nav.Link href="/#/content-pages">Pages</Nav.Link>
          <Nav.Link href="/#/faq-categories">Faqs</Nav.Link>
        </Nav>

        <Button type={"button"} title={"Profile"} className={"btn button2"} onClick={e => handleProfile(e)}/>
        <Button type={"button"} title={"Log Out"} className={"btn button3"} onClick={e => handleLogout(e)} />
      </Navbar>
    </div>
  )
}

function EmployerRole(props){
  return (
    <div style={{borderBottom:"1px solid rgb(212, 212, 212)"}}>
      <Navbar bg="white" variant="light" >
        <Navbar.Brand href="/#/my-dashboard">
          <img
              alt=""
              src={nas_logo}
              width="200"
              className="d-inline-block align-middle align-center navbar"
            /> 
        </Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link href="/#/job-application-submission">Post job listing</Nav.Link>
          <Dropdown className="resources-dropdown" title='Resources'>
            <Dropdown.Item className="dropdown-item item-hover">
              Starter pack
              <Dropdown.Submenu position='right'>
                <Dropdown.Item className="item-hover">
                  <a className="dropdown-link" href="https://www.autism.org.uk/what-we-do/employment/autism-at-work/employer-welcome-pack" target="_blank" rel="noopener noreferrer">
                    Employer welcome pack
                  </a>
                </Dropdown.Item>
                <Dropdown.Item className="item-hover">
                  <a className="dropdown-link" href="https://www.autism.org.uk/what-we-do/employment/autism-at-work/induction-pack-checklist" target="_blank" rel="noopener noreferrer">
                    Induction pack checklist
                  </a>
                </Dropdown.Item>
              </Dropdown.Submenu>
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item item-hover">
              Job listing advice
              <Dropdown.Submenu position='right'>
                <Dropdown.Item className="item-hover">
                  <a className="dropdown-link" href="https://www.autism.org.uk/what-we-do/employment/autism-at-work/job-advert-guidance" target="_blank" rel="noopener noreferrer">
                    Job advert guidance
                  </a>
                </Dropdown.Item>
                <Dropdown.Item className="item-hover">
                  <a className="dropdown-link" href="https://www.autism.org.uk/what-we-do/employment/autism-at-work/job-description-top-tips" target="_blank" rel="noopener noreferrer">
                    Job description top tips
                  </a>
                </Dropdown.Item>
                <Dropdown.Item className="item-hover">
                  <a className="dropdown-link" href="https://www.autism.org.uk/advice-and-guidance/topics/employment/seeking-work" target="_blank" rel="noopener noreferrer">
                  Advice and Guidance on Seeking Work
                  </a>
                </Dropdown.Item>
              </Dropdown.Submenu>
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item item-hover">
              Programmes
              <Dropdown.Submenu position='right'>
                <Dropdown.Item className="item-hover">
                  <a className="dropdown-link" href="https://www.autism.org.uk/what-we-do/employment/autism-work-programme" target="_blank" rel="noopener noreferrer">
                    Autism at work
                  </a>
                </Dropdown.Item>
                <Dropdown.Item className="item-hover">
                  <a className="dropdown-link" href="https://www.autism.org.uk/what-we-do/employment/hb-cares-programme" target="_blank" rel="noopener noreferrer">
                    Hospitality/HB cares
                  </a>
                </Dropdown.Item>
              </Dropdown.Submenu>
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item item-hover">
              Recruitment tips
              <Dropdown.Submenu position='right'>
                <Dropdown.Item className="item-hover">
                  <a className="dropdown-link" href="https://www.autism.org.uk/advice-and-guidance/topics/employment/employing-autistic-people/employers" target="_blank" rel="noopener noreferrer">
                    Recruitment top tips for employers
                  </a>
                </Dropdown.Item>
                <Dropdown.Item className="item-hover">
                  <a className="dropdown-link" href="https://www.autism.org.uk/what-we-do/employment/autism-at-work/employers-fact-sheet" target="_blank" rel="noopener noreferrer">
                    Interview fact sheet for employers
                  </a>
                </Dropdown.Item>
              </Dropdown.Submenu>
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item item-hover">
              Videos
              <Dropdown.Submenu position='right'>
                <Dropdown.Item className="item-hover">
                  <a className="dropdown-link" href="https://www.facebook.com/watch/?v=324172525451503" target="_blank" rel="noopener noreferrer">
                    Interviews with autism professionals
                  </a>
                </Dropdown.Item>
                <Dropdown.Item className="item-hover">
                  <a className="dropdown-link" href="https://www.autism.org.uk/advice-and-guidance/professional-practice/employment-adjustments-tips" target="_blank" rel="noopener noreferrer">
                    Reasonable adjustments
                  </a>
                </Dropdown.Item>
                <Dropdown.Item className="item-hover">
                  <a className="dropdown-link" href="https://www.autism.org.uk/advice-and-guidance/professional-practice/recruitment-interviews" target="_blank" rel="noopener noreferrer">
                    Recruitment and interviews
                  </a>
                </Dropdown.Item>
              </Dropdown.Submenu>
            </Dropdown.Item>
            <Dropdown.Item className="item-hover">
              <a className="dropdown-link" href="https://www.autism.org.uk/what-we-do/professional-development/training-and-conferences/employment/finding-employment" target="_blank" rel="noopener noreferrer">
                "Finding employment" online course
              </a>
            </Dropdown.Item>
          </Dropdown>
          
          <Dropdown className="resources-dropdown" title='Advice and Guidance'>
            <Dropdown.Item className="item-hover">
              <a className="dropdown-link" href="https://www.autism.org.uk/advice-and-guidance/topics/employment/employing-autistic-people/employers#H3_4" target="_blank" rel="noopener noreferrer">Guidance on Job Description</a>
            </Dropdown.Item>
          </Dropdown>
        </Nav>
        <Button type={"button"} title={"Profile"} className={"btn button2"} onClick={e => handleProfile(e)}/>
        <Button type={"button"} title={"Log Out"} className={"btn button3"} onClick={e => handleLogout(e)} />
      </Navbar>
    </div>
  )
}

function PreLoggedIn(props){
  return (
    <div className="center-image">
        <img
            src={nas_logo}
            alt = ""
            width="350"
        />
    </div>
  )
}

export default NavBar;