import React from 'react';
import JobListing from "./JobListing";
import { Scrollbars } from 'react-custom-scrollbars';

function JobListingsToLoad(props) {
    return (
        <div>
            <h2 className="column-title">{props.jobStatus}</h2>
            <div className="job-list">
                <Scrollbars style={{ height: 300 }}>
                        {props?.jobs.map((jobListing) => 
                            <div className="job-container">
                                <JobListing 
                                companyName={jobListing.companyName} 
                                title={jobListing.title} 
                                applicationDeadline={jobListing.applicationDeadline}
                                id={jobListing.id}
                                {...props}/>
                            </div>)}
                        {props?.jobs.length === 0 &&
                        <div style={{marginLeft: '1em', marginTop: '1em'}}>No listings to display</div>}
                </Scrollbars>
            </div>
        </div>
    )
}  

export default JobListingsToLoad;