import React, { useState, useEffect } from 'react';
import { withRouter,useLocation} from "react-router-dom";
import {getAllListings,deleteByQuestionId} from '../../services/FaqsService';
import Button from '../../components/Button/Button.js';
import './Faqs.css';

function FaqQuestions(props) {

    const [faqQuestions, setFaqQuestions] = useState([]);
    const [submitStatus, setSubmitStatus] = useState(0);
    const [action, setAction] = useState();
    const [categoryName, setCategoryName] = useState([]);
    const queryParams = useLocation().search;
    const faqCategoryId = new URLSearchParams(queryParams).get('faqCategoryId');  
    useEffect(() => {
        getAllListings(faqCategoryId).then(response => {
            setFaqQuestions(response);
            if((faqCategoryId!==null) && response[0]){
                const currentCategoryName=response[0].category_name;
                setCategoryName(currentCategoryName);
            }
        })
    }, [faqCategoryId]);

    function handleEditFaqQuestion(questionId) {
        if(faqCategoryId==="" || faqCategoryId===null){
            window.location.href='/#/update-faq-question?questionId='+questionId;
            /*if (window.confirm("Are you sure you want to edit this question?")) {
                window.location.href='/#/faqQuestions/edit-question?questionId='+questionId;
                deleteUser(userId).then((response) => {
                    window.location.reload();
                })
            }*/
        }else{
            window.location.href='/#/update-faq-question?questionId='+questionId+'&&faqCategoryId='+faqCategoryId;
        }
    }
    function truncateListString(formContent) {
        return formContent.length > 100 ? formContent.substring(0,100) + "..." : formContent;
    }
    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    
    function handleDeleteClick(questionId){
        if (window.confirm("Are you sure you want to delete this listing?")) {
            deleteByQuestionId(questionId).then(function (response) {
                console.log(response);
                setAction("delete");
                setSubmitStatus(1);
            })
            .catch(function (error) {
                setSubmitStatus(-1);
            });
        } else {
            window.location.reload();
        }
    }
    const handleAddFaqQuestion = (e) => {
        if(faqCategoryId==="" || faqCategoryId===null){
            window.location.href='/#/create-faq-question';
        }else{
            window.location.href='/#/create-faq-question?faqCategoryId='+faqCategoryId;
        }
    }
    const handleViewFaqCategories = (e) => {
        window.location.href='/#/faq-categories';
    }

    
    if (submitStatus === 1) {
        return (
            <div>
                <FaqSubmissionSuccess action={action} {...props}/>
            </div>
        );
    } else {
        return (
            <div className= "d-flex flex-column page">
                <h2 className="headingTitle"><b>{categoryName}</b> FAQ Questions</h2>
                <div className='actionButtonSection'>
                    <button type="submit" className="btn btn-primary add_faq" onClick={handleAddFaqQuestion}>Add New Question</button>
                    <button type="submit" className="btn btn-primary view_faq_categories" onClick={handleViewFaqCategories}>View Faq Categories</button>
                </div>
                {faqQuestions.length > 0 && 
                <>
                <div className= "table-div">      
                    <table className="table table-striped table-hover">
                        <thead className= "table-dark">
                        <tr >
                            <th scope="col">S.No</th>
                            <th scope="col">Question</th>
                            <th scope="col">Answer</th>
                            <th scope="col">Category Name</th>
                            <th scope="col">Status</th>
                            <th scope="col">Position</th>
                            <th scope="col">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {faqQuestions.map((faqQuestion,index)=>
                            <tr >
                                <th scope="row">{index+1}</th>
                                <td>{faqQuestion.question}</td>
                                <td class="faqQuestionColumn">{renderHTML(truncateListString(faqQuestion.answer))}</td>
                                <td>{(faqQuestion.category_name!==undefined && faqQuestion.category_name!==null)?faqQuestion.category_name:"--"}</td>
                                <td>{faqQuestion.status===1?"Enable":"Disable"}</td>
                                <td>{faqQuestion.position?faqQuestion.position:"--"}</td>
                                <td>
                                    <button type="submit" className="btn btn-primary" onClick={() => handleEditFaqQuestion(faqQuestion.id)}>Edit</button> | &nbsp;
                                    <Button type="submit" className="btn btn-danger " title={"Delete"} onClick={() => handleDeleteClick(faqQuestion.id)}/>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div> 
                </>}
                {faqQuestions.length === 0 &&
                <h2 className="smaller-title">You do not have any questions.</h2>}
            </div>
        )
                
    }
}
function FaqSubmissionSuccess({action, ...props}) {
    const handleFaqClick = (e) => {
        window.location.reload();
    }

    if (action === "delete") {
        return (
            <div className="general-form">
                <div className="submission-status">
                    <h1 className="title">Congratulations!</h1>
                    <br/>
                    <text className="account-state">You have successfully deleted this faq question!</text>
                </div>
                <br/>
                <br/>
                <div className="buttons">
                    <Button type="submit" title={"Back"} onClick={handleFaqClick}/>
                </div>
            </div>
        )
    }else {
        return (
            <div className="general-form">
                <div className="submission-status">
                    <h1 className="title">Sorry!</h1>
                    <br/>
                    <text className="account-state">Something went wrong</text>
                </div>
                <br/>
                <br/>
                <div className="buttons">
                    <Button type="submit" disabled={false} title={"Back"} onClick={handleFaqClick}/>
                </div>
            </div>
        )
    }
}

export default withRouter(FaqQuestions);



