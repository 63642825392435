import React, { useState, useEffect } from 'react';
import Button from '../../components/Button/Button.js';
import { withRouter } from "react-router-dom";
import "./DefaultDashboard.css";
import JobListingsToLoad from './JobListingsToLoad';
import { getAllLists, getAllListingsByEmployerId, downloadJoblistingsToCsv } from '../../services/JobService';
import { UNDER_REVIEW, REJECTED_LISTING, PAST_LISTINGS, CURRENT_LISTINGS } from "../../constants/jobConstants";
import UsersToLoad from './UsersToLoad';
import { getNewUsers } from '../../services/UserService.jsx';
import { SUPER_ADMIN, NEW_USERS } from '../../constants/userConstants';
function DefaultDashboard(props) {

    const [underReviewJobs, setUnderReviewJobs] = useState([]);
    const [rejectedJobs, setRejectedJobs] = useState([]);
    const [pastJobs, setPastJobs] = useState([]);
    const [currentJobs, setCurrentJobs] = useState([]);
    const [newUsers, setNewUsers] = useState([]);
    const [areThereJobs, setAreThereJobs] = useState();

    useEffect(() => {
        if (props.role === SUPER_ADMIN) {
            getAllLists().then((response) => {
                if(response.hasOwnProperty("active")){
                    setCurrentJobs(response.active);
                }
                if(response.hasOwnProperty("inactive")){
                    setPastJobs(response.inactive);
                }
                if(response.hasOwnProperty("pendingReview")){
                    setUnderReviewJobs(response.pendingReview);
                }
                setAreThereJobs(response.active.length === 0 && 
                    response.inactive.length === 0 &&
                    response.pendingReview.length === 0 &&
                    response.rejected.length === 0);
            });
            getNewUsers().then((response) => setNewUsers(response))
            .catch(function (error) {
                setNewUsers([]);
            });
        } else {
            getAllListingsByEmployerId(props.employerId).then((response) => {
                let localActivejobs = [];
                let localReviewJobs = [];
                let localInactiveJobs = [];
                let localRejectedJobs = [];
                
                for (const listing of Object.values(response)) {
                    switch (listing.status) {
                        case "ACTIVE": 
                            localActivejobs.push(listing);
                            break;
                        case "PENDINGREVIEW":
                            localReviewJobs.push(listing);
                            break;
                        case "INACTIVE":
                            localInactiveJobs.push(listing);
                            break;
                        case "REJECTED":
                            localRejectedJobs.push(listing);
                            break;
                        default:
                            break;
                    }
                }
                setCurrentJobs(localActivejobs);
                setUnderReviewJobs(localReviewJobs);
                setPastJobs(localInactiveJobs);
                setRejectedJobs(localRejectedJobs);
                setAreThereJobs(localActivejobs.length === 0 && 
                    localReviewJobs.length === 0 &&
                    localInactiveJobs === 0 &&
                    localRejectedJobs === 0);
            })
            .catch(function (error) {
                setNewUsers([]);
            });
        }
    }, [props.employerId,props.role])

    const handleDownloadReport = (e) => {
        downloadJoblistingsToCsv(props.employerId);
    }

    return (
        <div className="form-group">
            <span className="download-report">
                Download as: 
                <Button type="submit" disabled={areThereJobs} title={"a spreadsheet"} onClick={handleDownloadReport}/>
            </span>
            <div className="row">
                <div className="column" margin-right>
                    { currentJobs && <JobListingsToLoad key={currentJobs.id} jobStatus={CURRENT_LISTINGS} jobs={currentJobs} {...props}/>}
                </div>
                <div className="column">
                    { underReviewJobs && <JobListingsToLoad key={underReviewJobs.id} jobStatus={UNDER_REVIEW} jobs={underReviewJobs} {...props}/> }
                </div>
            </div>
            <div className="row">
                <div className="column">
                    { pastJobs && <JobListingsToLoad key={pastJobs.id} jobStatus={PAST_LISTINGS} jobs={pastJobs} {...props}/>}
                </div>
                {props.role === SUPER_ADMIN ?
                    <div className="column">
                        <UsersToLoad status={NEW_USERS} users={newUsers} {...props}/>
                    </div>
                : 
                    <div className="column">
                        { rejectedJobs && <JobListingsToLoad key={rejectedJobs.id} jobStatus={REJECTED_LISTING} jobs={rejectedJobs} {...props}/>}
                    </div>}
            </div>
        </div>
        )
}

export default withRouter(DefaultDashboard);