import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { BiHelpCircle } from 'react-icons/bi';
import Button from '../../components/Button/Button.js';
import AlertDismissible from '../../components/AlertDismissible/AlertDismissible.js';
/*import { EMPTY_STRING } from "../../constants/formConstants";*/
import {addNewEmployer} from '../../services/UserService';
import RichTextEditor from 'react-rte';
import { useForm } from "react-hook-form";
import {textEditorFieldCheck} from '../../components/Functions/Common.js';

const emailRegex = /[a-z]+(\.|_)?([a-z0-9]+(\.|_)?)+?[a-z0-9]+@[a-z0-9]+_?[a-z0-9]+\.[a-z]+/;

function Form(props) {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [state, setState] = useState({
        aboutCompany: RichTextEditor.createEmptyValue(),
        companySpecialize: RichTextEditor.createEmptyValue(),
        companyPractices: RichTextEditor.createEmptyValue(),
    })
    const [aboutCompanyError, setAboutCompanyError] = useState(false);
    const [companySpecializeError, setCompanySpecializeError] = useState(false);
    const [companyPracticesError, setCompanyPracticesError] = useState(false);
    
    const handleACOnChange = (value) => {
        setState(prevState => ({
            ...prevState,
            aboutCompany:value
        }))
        if (props.OnChange) {
            props.OnChange(
                value.toString('html')
            );
        }
    };
    const handleCSOnChange = (value) => {
        setState(prevState => ({
            ...prevState,
            companySpecialize:value
        }))
        if (props.OnChange) {
            props.OnChange(
                value.toString('html')
            );
        }
    };
    const handleCPOnChange = (value) => {
        setState(prevState => ({
            ...prevState,
            companyPractices:value
        }))
        if (props.OnChange) {
            props.OnChange(
                value.toString('html')
            );
        }
    };
    /*const [emailValid, setEmailValid] = useState(true);
    */

    /*const maxChar = 200;
    const [charsLeft, setCharsLeft] = useState(200);*/
    /*const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))        
    }
    var checkFormValid=true;
    Object.entries(state).forEach(entry => {
        const [key, value] = entry;
        var array = ["aboutCompany","companySpecialize","companyPractices"];
        if(!array.includes(key) && (value==='')){
            checkFormValid=false;
        }
    });
    /*const formFilled = Object.values(state).every(v => v.length);*/
    /*const emailValid = emailRegex.test(state.companyEmail);*/
    
    /*const handleCEMailChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            companyEmail: value
        }))  
        setEmailValid(emailRegex.test(value));
    };
    const formValid = checkFormValid && emailValid;
    /*const wordCount = (e) => {
        let currentText = e.target.value.length;
        setCharsLeft(maxChar-currentText);
    }*/

    const handleSubmitClick = (data) => {
        /*data.preventDefault();*/
        const aboutCompany=state.aboutCompany.toString('html');
        const companySpecialize= state.companySpecialize.toString('html');
        const companyPractices=state.companyPractices.toString('html');
        var isAboutCompnay=(textEditorFieldCheck(aboutCompany)===false)?true:false;
        var isCompnaySpecialize=(textEditorFieldCheck(companySpecialize)===false)?true:false;
        var isCompnayPractices=(textEditorFieldCheck(companyPractices)===false)?true:false; 
        setAboutCompanyError(isAboutCompnay);
        setCompanySpecializeError(isCompnaySpecialize);
        setCompanyPracticesError(isCompnayPractices);
        if(!isAboutCompnay && !isCompnaySpecialize && !isCompnayPractices){
            const payload = {
                "name": data.companyName,
                "companyWebsite": data.website,
                "companyContactName": data.companyContactName,
                "companyEmail": data.companyEmail,
                "aboutCompany": aboutCompany,
                "companySpecialize": companySpecialize,
                "companyPractices":companyPractices
            }
            addNewEmployer(payload).then(function (response) {
                props.onFormSubmit(1);
            }).catch(function (error) {
                props.onFormSubmit(-1);
            });
        }else{
            alert("Something went wrong");
        }
    }

    return (
        <div className="form-margin">
            <div className="title-container">
                <h1 className="title">Add New employer</h1>
                {props.status === -1 && <AlertDismissible onClose={() => props.onFormSubmit(0)} alertMessage={"Something went wrong when adding this employer. Please try again."}/>}
            </div> 
            <form onSubmit={handleSubmit(handleSubmitClick)}>
                <div className="form-group">
                    <label for="companyName">Company name <label style={{ color: 'red' }}>*</label></label>
                    <input className={"form-control company-name"} id="companyName" placeholder="Company name" {...register("companyName",{ required: true })} />
                    {errors.companyName && <span className='error'>Company name is required.</span>}         
                </div>
                <div className="form-group">
                    <label for="website">Website <label style={{ color: 'red' }}>*</label></label>
                    <input className="form-control" id="website" placeholder="Website" {...register("website",{ required: true})} />
                    {errors.website && <span className='error'>Company website is required.</span>} 
                </div>
                <div className="form-group">
                    <label for="companyContactName">Company contact name <label style={{ color: 'red' }}>*</label></label>
                    <input className={"form-control company-contact-name"} id="companyContactName" placeholder="Company contact name"  {...register("companyContactName",{ required: true })} />
                    {errors.companyContactName && <span className='error'>Contact person name is required.</span>} 
                </div>
                <div className="form-group">
                    <label for="companyEmail">Company email <label style={{ color: 'red' }}>*</label></label>
                    <input className={"form-control"} id="companyEmail" placeholder="Company email"  {...register("companyEmail",{ required: true,pattern: emailRegex})} />
                    {errors.companyEmail && <span className='error'>Company email is required.</span>}
                </div>
                <div className="form-group">
                    <label for="aboutCompany">About the company <label style={{ color: 'red' }}>*</label></label>
                    <BiHelpCircle data-tip="React-tooltip" data-for="aboutCompany-tooltip"/>
                    <ReactTooltip id="aboutCompany-tooltip" place="right" type="dark" effect="float">
                        <span>
                            Focus on any autism positive/friendly practices here: e.g., ‘we encourage diverse candidates to apply and invite you to talk to us about reasonable adjustments that we may be able make for you’.
                        </span>
                    </ReactTooltip>
                    <RichTextEditor  id="aboutCompany" value={state.aboutCompany} onChange={handleACOnChange}/>
                    {aboutCompanyError && <span className='error'>Please enter about your company becuase it is required.</span>}
                </div>
                <div className="form-group">
                    <label for="companySpecialize">What is your specialism <label style={{ color: 'red' }}>*</label></label>
                    <BiHelpCircle data-tip="React-tooltip" data-for="companySpecialize-tooltip"/>
                    <ReactTooltip id="companySpecialize-tooltip" place="right" type="dark" effect="float">
                        <span>What is your specialism in</span>
                    </ReactTooltip>
                    <RichTextEditor  id="companySpecialize" value={state.companySpecialize} onChange={handleCSOnChange}/>
                    {companySpecializeError && <span className='error'>Please enter company specialism becuase it is required.</span>}  
                </div>
                <div className="form-group">
                    <label for="companyPractices">What practices do you currently employ for those prospective candidates on the autistic spectrum <label style={{ color: 'red' }}>*</label></label>
                    <BiHelpCircle data-tip="React-tooltip" data-for="companyPractices-tooltip"/>
                    <ReactTooltip id="companyPractices-tooltip" place="right" type="dark" effect="float">
                        <span>What practices do you currently employ for those prospective candidates on the autistic spectrum</span>
                    </ReactTooltip>
                    <RichTextEditor  id="companyPractices" value={state.companyPractices} onChange={handleCPOnChange}/>
                    {companyPracticesError && <span className='error'>Please enter company practices becuase it is required.</span>}  
                </div>
                <div>
                    <Button type={"submit"} title={"Add employer"} />
                </div>
            </form>
        </div>
    )
}  

export default Form;