import axios from "axios";
/*import FileDownload from "js-file-download";*/
import {
  API_BASE_URL,
  API_URL_EXTENSION,
  ACCESS_TOKEN_NAME,
} from "../constants/apiConstants";

/** Start Categories operations */
function createFaqCategory(payload) {
  const url = API_BASE_URL + API_URL_EXTENSION + "/faqcategories/create-category";
  const body = {
    "name": payload.name,
    "position": payload.position,
    "status": payload.status
  };
  return axios.post(url, body, {'headers': {'Authorization':localStorage.getItem(ACCESS_TOKEN_NAME)}})
      .then(function(response) {
          return response.data
      })
}
function getAllCategoriesListings(faqCategoryId) {
  const url = 
    API_BASE_URL + 
    API_URL_EXTENSION + 
    "/faqcategories/get-all-faq-categories";
  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return response.data;
    });
}

function getCategoryById(categoryId) {
  const url =
    API_BASE_URL +
    API_URL_EXTENSION +
    "/faqcategories/get-category-by-id?categoryId="+categoryId;
  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return {};
    });
}

function updateFaqCategory(payload) {
  const url = API_BASE_URL + API_URL_EXTENSION + `/faqcategories/update-faq-category/${payload.id}`;
  const body = {
    "id": payload.id,
    "name": payload.name,
    "position": payload.position,
    "status": payload.status
  };
  return axios.put(
    url, 
    body, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function(response) {
      return response.data
    });
}

function deleteByCategoryId(categoryId) {
  const url = API_BASE_URL + API_URL_EXTENSION + "/faqcategories/delete-faq-category?categoryId=" + categoryId;
  return axios
    .delete(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
      data: {}
    })
    .then(function (response) {
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    })
    .catch(function (error) {
      return false;
    });
}

function getAllActiveCategories(faqCategoryId) {
  const url = 
    API_BASE_URL + 
    API_URL_EXTENSION + 
    "/faqcategories/get-active-faq-categories";
  
  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      if(Object.keys(response.data).length>0){
        let preferencesOptions = [];
        preferencesOptions=generateKeyPairObject(response.data);
        return preferencesOptions;
      }
      return response.data;
    });
}

/** gernerate key object for options */
function generateKeyPairObject(dataPayload){
  let optionsData=[];
  if(dataPayload.length>0){
    for(let i = 0; i < dataPayload.length; i++) {
      optionsData.push({
        value:dataPayload[i].id,
        key: dataPayload[i].id,
        label:dataPayload[i].name,
      });
    }
  }
  return optionsData;
}



/** Start Question operations */
function createQuestion(payload) {
  const url = API_BASE_URL + API_URL_EXTENSION + "/faqs/create-question";
  const body = {
    "question": payload.question,
    "categoryId": payload.category_id,
    "answer": payload.answer,
    "status": payload.status,
    "position":payload.position
  };

  return axios.post(url, body, {'headers': {'Authorization':localStorage.getItem(ACCESS_TOKEN_NAME)}})
      .then(function(response) {
          return response.data
      })
}

function updateQuestion(payload) {
  const url = API_BASE_URL + API_URL_EXTENSION + `/faqs/update-question/${payload.id}`;
  const body = {
    "id": payload.id,
    "question": payload.question,
    "categoryId": payload.category_id,
    "answer": payload.answer,
    "status": payload.status,
    "position":payload.position
  };
  console.log(body);
  return axios.put(
    url, 
    body, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function(response) {
      return response.data
    });
}
function getAllListings(faqCategoryId) {
  var url = 
    API_BASE_URL + 
    API_URL_EXTENSION + 
    "/faqs/get-all-questions-answers";
  if(faqCategoryId!==null){
    url+="?categoryId="+faqCategoryId;
  }
  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return response.data;
    });
}

function getQuestionById(questionId) {
  const url =
    API_BASE_URL +
    API_URL_EXTENSION +
    "/faqs/get-question-by-id?questionId="+questionId;
  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return {};
    });
}

function deleteByQuestionId(questionId) {
  const url = API_BASE_URL + API_URL_EXTENSION + "/faqs/delete-question?questionId=" + questionId;
  return axios
    .delete(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
      data: {}
    })
    .then(function (response) {
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    })
    .catch(function (error) {
      return false;
    });
}


/*function getAllListingsByEmployerId(employerId) {
  const url = 
    API_BASE_URL + 
    API_URL_EXTENSION + 
    "/joblisting/byEmployerIdAndStatus?employerId=" + employerId;
  
  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return response.data;
    });
}*/

/*function getListingCountByStatus(employerId) {
  const url =
    API_BASE_URL +
    API_URL_EXTENSION +
    "/joblisting/countByStatus?employerId=" +
    employerId;

  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    });
}*/

/*function deleteListing(listingId) {
  const url = API_BASE_URL + API_URL_EXTENSION + "/joblisting/delete?listingId=" + listingId;
  return axios
    .delete(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
      data: {}
    })
    .then(function (response) {
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    })
    .catch(function (error) {
      return false;
    });
}*/

/*function downloadJoblistingsToCsv(employerId) {
  let url = 
    API_BASE_URL + 
    API_URL_EXTENSION + 
    "/joblisting/downloadAsCsv";
  
  if (employerId) {
    url += "?employerId=" + employerId;
  }

  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
      responseType: "blob",
    })
    .then(function (response) {
      FileDownload(response.data, "listings-report.csv");
    })
    .catch(function (error) {
      return [];
    });
}*/


export {
  createFaqCategory,
  getAllCategoriesListings,
  getCategoryById,
  updateFaqCategory,
  deleteByCategoryId,
  getAllActiveCategories,
  createQuestion,
  getAllListings,
  getQuestionById,
  updateQuestion,
  deleteByQuestionId
};