import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import {getAllCategoriesListings,deleteByCategoryId} from '../../services/FaqsService';
import Button from '../../components/Button/Button.js';
import './Faqs.css';

function FaqCategories(props) {

    const [faqCategories, setFaqCategories] = useState([]);
    const [submitStatus, setSubmitStatus] = useState(0);
    const [action, setAction] = useState();
    useEffect(() => {
        getAllCategoriesListings().then(response => {
            setFaqCategories(response)
        })
    }, []);

    function handleEditFaqQuestion(categoryId) {        
        window.location.href='/#/update-faq-category?categoryId='+categoryId;
        /*if (window.confirm("Are you sure you want to edit this question?")) {
            window.location.href='/#/faqs/edit-question?categoryId='+categoryId;
            deleteUser(userId).then((response) => {
                window.location.reload();
            })
        }*/
    }
        
    function handleDeleteClick(categoryId){
        if (window.confirm("Are you sure you want to delete this listing?")) {
            deleteByCategoryId(categoryId).then(function (response) {
                setAction("delete");
                setSubmitStatus(1);
            })
            .catch(function (error) {
                setSubmitStatus(-1);
            });
        } else {
            //window.location.reload();
        }
    }
    const handleAddNewCategory = (e) => {
        window.location.href='/#/create-faq-category';
    }
    
    function handleViewFaqQuestion(categoryId) { 
        console.log(categoryId);
        if((categoryId!=="") && (categoryId!==null)){
            window.location.href='/#/faq-questions?faqCategoryId='+categoryId;
        }else{
            window.location.href='/#/faq-questions';
        }
    }
    if (submitStatus === 1) {
        return (
            <div>
                <FaqSubmissionSuccess action={action} {...props}/>
            </div>
        );
    } else {
        return (
            <div className= "d-flex flex-column page">
                <h2 className="headingTitle">FAQ Categories</h2>
                <div className='actionButtonSection'>
                    <button type="submit" className="btn btn-primary add_faq" onClick={handleAddNewCategory}>Add New Category</button>
                    <button type="submit" className="btn btn-primary faq_question" onClick={() => handleViewFaqQuestion("")}>FAQ Questions</button>
                </div>
                {faqCategories.length > 0 && 
                <>
                <div className= "table-div">      
                    <table className="table table-striped table-hover">
                        <thead className= "table-dark">
                        <tr >
                            <th scope="col">S.No</th>
                            <th scope="col">Name</th>
                            <th scope="col">Position</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {faqCategories.map((faqCategory,index)=>
                            <tr >
                                <th scope="row">{index+1}</th>
                                <td>{faqCategory.name}</td>
                                <td>{faqCategory.position}</td>
                                <td>{faqCategory.status===1?"Enable":"Disable"}</td>
                                <td>
                                    <button type="submit" className="btn btn-primary" onClick={() => handleViewFaqQuestion(faqCategory.id)}>View Questions</button> | &nbsp;
                                    <button type="submit" className="btn btn-primary" onClick={() => handleEditFaqQuestion(faqCategory.id)}>Edit</button> | &nbsp;
                                    <Button type="submit" className="btn btn-danger " title={"Delete"} onClick={() => handleDeleteClick(faqCategory.id)}/>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div> 
                </>}
                {faqCategories.length === 0 &&
                <h2 className="smaller-title">You do not have any categories.</h2>}
            </div>
        )
                
    }
}
function FaqSubmissionSuccess({action, ...props}) {
    const handleFaqClick = (e) => {
        window.location.reload();
    }

    if (action === "delete") {
        return (
            <div className="general-form">
                <div className="submission-status">
                    <h1 className="title">Congratulations!</h1>
                    <br/>
                    <text className="account-state">You have successfully deleted this faq category!</text>
                </div>
                <br/>
                <br/>
                <div className="buttons">
                    <Button type="submit" title={"Back"} onClick={handleFaqClick}/>
                </div>
            </div>
        )
    }else {
        return (
            <div className="general-form">
                <div className="submission-status">
                    <h1 className="title">Sorry!</h1>
                    <br/>
                    <text className="account-state">Something went wrong</text>
                </div>
                <br/>
                <br/>
                <div className="buttons">
                    <Button type="submit" disabled={false} title={"Back"} onClick={handleFaqClick}/>
                </div>
            </div>
        )
    }
}

export default withRouter(FaqCategories);



