import React, { useState } from 'react';
import './AddEmployer.css';
import { withRouter } from "react-router-dom";
import Form from './Form';
import Button from '../../components/Button/Button.js';


function AddEmployer(props) {

    const [submitStatus, setSubmitStatus] = useState(0);

    return (
        <div className="general-form add-employer-form border-opacity-25">
            {submitStatus === 1
                ? <SubmitStatus {...props}/>
                : <Form status={submitStatus} role={props.role} onFormSubmit={setSubmitStatus} {...props}/>
            }
        </div>
    )
}

function SubmitStatus(props) {

    const redirectToDashboard = (e) => {
        e.preventDefault()
        props.history.push('/');
    }

    return (
        <div className="submission-status">
            <h1 className="title">Congratulations!</h1>
            <br/>
            <div className="account-state">You have successfully added a new employer!</div>
            <div>
                <br/>
                <Button type={"submit"} title={"Back to Dashboard"} onClick={redirectToDashboard} />
            </div>
        </div>
    )
}

export default withRouter(AddEmployer);