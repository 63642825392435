import React from 'react';

function JobListings(props) {


    const jobUrl = `job?employer=${props.companyName}&id=${props.id}`;

    const handleClick = (e) => {
        e.preventDefault();
        props.history.push(jobUrl);
    }

    return (
        <a className="click-job" href={jobUrl} onClick={handleClick}>
            <div className="job">
                <div>
                    {props.companyName}
                </div>
                <div className="job-bottom">
                    <div>
                        {props.title}
                    </div>
                    <div>
                        {props.applicationDeadline}
                    </div>
                </div>
            </div>
        </a>
    )
}

export default JobListings;