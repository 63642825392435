import React, { useState, useEffect } from 'react';
import Button from '../../components/Button/Button.js';
import AlertDismissible from '../../components/AlertDismissible/AlertDismissible.js';
import  {getAllEmployers } from '../../services/LoginService.jsx';
import { postJobSubmission,optionsToCommaString,getAllPreferences,getAllActiveSectorJobRoles } from '../../services/JobService.jsx';
import { getEmployer } from '../../services/UserService.jsx';
import { withRouter } from "react-router-dom";
import '../Job/Job.css';
import ReactTooltip from 'react-tooltip';
import { BiHelpCircle } from 'react-icons/bi';
import Dropdown from "react-bootstrap/Dropdown";
/*import Form from "react-bootstrap/Form";*/
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { EMPTY_STRING } from "../../constants/formConstants";
import { SUPER_ADMIN } from '../../constants/userConstants';

import Select from 'react-select';
import RichTextEditor from 'react-rte';
import { useForm } from "react-hook-form";
import {textEditorFieldCheck,textSelectedFieldCheck,fieldErrorLengthCheck} from '../../components/Functions/Common.js';
 
function Job(props) {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const [applicationDeadline, setApplicationDeadline] = useState();
    const [listOfCompanies, setListOfCompanies] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isApplicantRoles, setIsApplicantRoles] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(0);
    const [listOfJobRoles, setListOfJobRoles] = useState([]);
    const [listOfJobRegions, setListOfJobRegions] = useState([]);
    const [listOfJobSectors, setListOfJobSectors] = useState([]);
    const [listOfContractTypes, setListOfContractTypes] = useState([]);
    const [listOfWorkLocations, setListOfWorkLocations] = useState([]);
    const [listOfWorkHours, setListOfWorkHours] = useState([]);

    const [selectedJobRolesOptions,setSelectedJobRolesOptions]= useState("");
    const [selectedContractTypesOptions,setSelectedContractTypesOptions]= useState("");
    const [selectedJobSectorsOptions,setSelectedJobSectorsOptions]= useState("");
    const [selectedJobRegionsOptions,setSelectedJobRegionsOptions]= useState("");
    const [selectedJobWorkLocationsOptions,setSelectedJobWorkLocationsOptions]= useState("");
    const [selectedWorkingHoursOptions,setSelectedWorkingHoursOptions]= useState("");

    /** Empty error validation */
    const [companyNameError, setCompanyNameError] = useState(false);
    const [jobSectorsError, setJobSectorsError] = useState(false);
    const [rolesAvailableError, setRolesAvailableError] = useState(false);
    const [jobRegionsError, setJobRegionsError] = useState(false);
    const [contractTypeError, setContractTypeError] = useState(false);
    const [jobWorkLocationsError, setJobWorkLocationsError] = useState(false);
    const [workingHoursError, setWorkingHoursError] = useState(false);
    const [roleDescriptionError, setRoleDescriptionError] = useState(false);
    const [howToApplyError, setHowToApplyError] = useState(false);
    
    const [state, setState] = useState({
        companyName: EMPTY_STRING,
        aboutCompany: RichTextEditor.createEmptyValue(),
        aboutTeam: RichTextEditor.createEmptyValue(),
        roleDescription: RichTextEditor.createEmptyValue(),
        howToApply:RichTextEditor.createEmptyValue(),
        companySpecialize: RichTextEditor.createEmptyValue(),
        companyPractices: RichTextEditor.createEmptyValue(),
    })
     
    useEffect(() => {
        /** Get getAllPreferences */
        getAllPreferences('preferences').then((response) => {
            if(Object.keys(response).length>0){
                let preferencesResp=response;
                Object.keys(preferencesResp).forEach(key => {
                    let currentKeyData=preferencesResp[key];
                    if (currentKeyData.length === 0) {
                        setSubmitStatus(-1);
                    } else {
                        switch(key) {
                            case 'job_roles':
                                setListOfJobRoles([]);
                                /*setListOfJobRoles(currentKeyData);*/
                                break;
                            case 'job_regions':
                                setListOfJobRegions(currentKeyData);
                                break;
                            case 'job_sectors':
                                setListOfJobSectors(currentKeyData);
                                break;
                            case 'job_contract_types':
                                setListOfContractTypes(currentKeyData);
                                break;
                            case 'job_work_locations':
                                setListOfWorkLocations(currentKeyData);
                                break;
                            case 'job_work_hours':
                                setListOfWorkHours(currentKeyData);
                                break;
                            default:
                                return "";
                        }
                    }
                });
            } 
        }).catch(function () {
            setSubmitStatus(-1);
        });
        if (props.role === SUPER_ADMIN) {
            setIsAdmin(true);
            getAllEmployers().then((response) => {
                if (response.length === 0) {
                    setSubmitStatus(-1);
                } else {
                    setListOfCompanies(response);
                }
            }).catch(function () {
                setSubmitStatus(-1);
            });
        } else {
            setIsAdmin(false);
            getEmployer(props.employerId).then((response) => {
                setState(prevState => ({
                    ...prevState,
                    companyName: response.name,
                    aboutCompany: RichTextEditor.createValueFromString(response.aboutCompany, 'html'),
                    companySpecialize: RichTextEditor.createValueFromString(response.companySpecialize, 'html') || "",
                    companyPractices: RichTextEditor.createValueFromString(response.companyPractices, 'html') || ""
                }))
            }        
            ).catch(function () {
            setSubmitStatus(-1);
        })};
    }, [props.employerId,props.role]);

    const handleSelect = (e, value) => {
        const { id, text } = value.target
        //console.log(value.target);
        setState(prevState => ({
            ...prevState,
            [id]: text
        }))
        if (id === "companyName") {
            const companyIndex = listOfCompanies.findIndex(element =>
                element.name === text
            );
            const companyInfoOn=listOfCompanies[companyIndex];
            setState(prevState => ({
                ...prevState,
                aboutCompany: RichTextEditor.createValueFromString(companyInfoOn.aboutCompany, 'html') || "",
                companySpecialize: RichTextEditor.createValueFromString(companyInfoOn.companySpecialize, 'html') || "",
                companyPractices: RichTextEditor.createValueFromString(companyInfoOn.companyPractices, 'html') || ""
            }))
            setCompanyNameError(false);
        }
    }
    /*const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }*/
    
    const handleTeamOnChange = (value) => {
        setState(prevState => ({
            ...prevState,
            aboutTeam:value
        }))
        if (props.OnChange) {
            // Send the changes up to the parent component as an HTML string.
            // This is here to demonstrate using `.toString()` but in a real app it
            // would be better to avoid generating a string on each change.
            props.OnChange(
                value.toString('html')
            );
        }
    };
    const handleRoleOnChange = (value) => {
        setState(prevState => ({
            ...prevState,
            roleDescription:value
        }))
        if (props.OnChange) {
            // Send the changes up to the parent component as an HTML string.
            // This is here to demonstrate using `.toString()` but in a real app it
            // would be better to avoid generating a string on each change.
            props.OnChange(
                value.toString('html')
            );
            setRoleDescriptionError(false);
        }
    };
    const handleHowToApplyOnChange = (value) => {
        setState(prevState => ({
            ...prevState,
            howToApply:value
        }))
        if (props.OnChange) {
            props.OnChange(
                value.toString('html')
            );
        }
    };    
    const handleACOnChange = (value) => {
        setState(prevState => ({
            ...prevState,
            aboutCompany:value
        }))
        if (props.OnChange) {
            props.OnChange(
                value.toString('html')
            );
        }
    };
    const handleCSOnChange = (value) => {
        setState(prevState => ({
            ...prevState,
            companySpecialize:value
        }))
        if (props.OnChange) {
            props.OnChange(
                value.toString('html')
            );
        }
    };
    const handleCPOnChange = (value) => {
        setState(prevState => ({
            ...prevState,
            companyPractices:value
        }))
        if (props.OnChange) {
            props.OnChange(
                value.toString('html')
            );
        }
    };
    
    /*var checkFormValid=true;
    Object.entries(state).forEach(entry => {
        const [key, value] = entry;
        var array = ["roleDescription","howToApply", "aboutTeam","aboutCompany","companySpecialize","companyPractices"];
        if(!array.includes(key) && (value==='')){
            checkFormValid=false;
        }
    });
    const selectionFields = {'jobRoles':selectedJobRolesOptions,'contractTypes':selectedContractTypesOptions,'jobRegions':selectedJobRegionsOptions,'jobWorkLocations':selectedJobWorkLocationsOptions,'workingHours':selectedWorkingHoursOptions,'jobSectors':[selectedJobSectorsOptions]};
    const checkSelectFieldValid = Object.values(selectionFields).every(v => v.length);
    const formValid=(checkFormValid===true)?checkSelectFieldValid:checkFormValid;*/
    
    const handleSubmitClick =(data) => {
        /*data.preventDefault();*/
        const selectedjobRolesOption=optionsToCommaString(selectedJobRolesOptions);        
        /*const selectedjobSectorsOption=optionsToCommaString(selectedJobSectorsOptions);*/
        let selectedjobSectorsOption=null;
        if(Object.keys(selectedJobSectorsOptions).length>0){
            selectedjobSectorsOption=selectedJobSectorsOptions.value;
        }
        const selectedjobRegionsOption=optionsToCommaString(selectedJobRegionsOptions);
        const selectedContractTypesOption=optionsToCommaString(selectedContractTypesOptions);
        const selectedJobWorkLocationsOption=optionsToCommaString(selectedJobWorkLocationsOptions);
        const selectedWorkingHoursOption=optionsToCommaString(selectedWorkingHoursOptions);
        const isCompanyName=(textSelectedFieldCheck(state.companyName)===false)?true:false; 
        setCompanyNameError(isCompanyName);
        const isJobSectors=(textSelectedFieldCheck(selectedJobSectorsOptions)===false)?true:false; 
        setJobSectorsError(isJobSectors);
        const isRolesAvailable=(textSelectedFieldCheck(selectedjobRolesOption)===false)?true:false; 
        setRolesAvailableError(isRolesAvailable);
        const isJobRegions=(textSelectedFieldCheck(selectedjobRegionsOption)===false)?true:false; 
        setJobRegionsError(isJobRegions);
        const isContractType=(textSelectedFieldCheck(selectedContractTypesOption)===false)?true:false; 
        setContractTypeError(isContractType);
        const isJobWorkLocations=(textSelectedFieldCheck(selectedJobWorkLocationsOption)===false)?true:false; 
        setJobWorkLocationsError(isJobWorkLocations);
        const isWorkingHours=(textSelectedFieldCheck(selectedWorkingHoursOption)===false)?true:false; 
        setWorkingHoursError(isWorkingHours);
        const roleDescription=state.roleDescription.toString('html');
        const isRoleDescription=(textEditorFieldCheck(roleDescription)===false)?true:false; 
        setRoleDescriptionError(isRoleDescription);
        const howToApply=state.howToApply.toString('html');
        const isHowToApply=(textEditorFieldCheck(howToApply)===false)?true:false; 
        setHowToApplyError(isHowToApply);
        /*console.log(isCompanyName);
        console.log(isJobSectors);
        console.log(isRolesAvailable);
        console.log(isJobRegions);
        console.log(isContractType);
        console.log(isJobWorkLocations);
        console.log(isWorkingHours);
        console.log(isRoleDescription);*/
        if(!isCompanyName && !isJobSectors && !isRolesAvailable && !isJobRegions && !isContractType && !isJobWorkLocations && !isWorkingHours && !isRoleDescription && !isHowToApply){        
            const payload = {
                "employerId": props.employerId,
                "companyName": state.companyName,
                "applicationDeadline": applicationDeadline,
                "title": data.title,
                "workingHours":selectedWorkingHoursOption,
                "contractType":selectedContractTypesOption,
                "salary": data.salary,            
                "city": state.city,
                "aboutCompany": state.aboutCompany.toString('html'),
                "aboutTeam": state.aboutTeam.toString('html'),
                "contact": data.contact,
                "roleDescription": state.roleDescription.toString('html'),
                "rolesAvailable": selectedjobRolesOption,
                "careerLevel": data.careerLevel,       
                "link": data.link,       
                "jobSectors": selectedjobSectorsOption,          
                "jobRegions": selectedjobRegionsOption,       
                "workingLocations":selectedJobWorkLocationsOption,
                "companySpecialize": state.companySpecialize.toString('html'),
                "companyPractices": state.companyPractices.toString('html'),
                "howToApply":state.howToApply.toString('html')
            } 
            postJobSubmission(payload).then(function (response) {
                setSubmitStatus(1)
            })
            .catch(function () {
                setSubmitStatus(-1)
            });
        }else{
            window.scrollTo(0,100);
            alert("Please check your job listing form field, Maybe some fileds are empty");
        }
    }
    /** Job Sectors */
    const handleJobSectorsChange = selectedJobSectorsOptions => {
        setSelectedJobSectorsOptions(selectedJobSectorsOptions);
        setJobSectorsError(fieldErrorLengthCheck(selectedJobSectorsOptions));
        /** Set Job Roles on the basis of sector */
        /** Get Job Roles */
        getAllActiveSectorJobRoles(selectedJobSectorsOptions.value).then((response) => {
            if(Object.keys(response).length>0){
                setSelectedJobRolesOptions("");
                setListOfJobRoles(response);
                setIsApplicantRoles(true);
            } 
        }).catch(function () {
            setListOfJobRoles([]);
        });
    };
    /** Job Roles */
    const handleJobRolesChange = selectedJobRolesOptions => {
        setSelectedJobRolesOptions(selectedJobRolesOptions);
        setRolesAvailableError(fieldErrorLengthCheck(selectedJobRolesOptions));
    };
    /** Job Regions */
    const handleJobRegionsChange = selectedJobRegionsOptions => {
        setSelectedJobRegionsOptions(selectedJobRegionsOptions);
        setJobRegionsError(fieldErrorLengthCheck(selectedJobRegionsOptions));
    };
    /** Job ContractType */
    const handleContractTypesChange = selectedContractTypesOptions => {
        setSelectedContractTypesOptions(selectedContractTypesOptions);
        setContractTypeError(fieldErrorLengthCheck(selectedContractTypesOptions));
    };
    /** Job JobWorkLocations */
    const handleJobWorkLocationsChange = selectedJobWorkLocationsOptions => {
        setSelectedJobWorkLocationsOptions(selectedJobWorkLocationsOptions);
        setJobWorkLocationsError(fieldErrorLengthCheck(selectedJobWorkLocationsOptions));
    };
    /** Job WorkingHours */
    const handleWorkingHoursChange = selectedWorkingHoursOptions => {
        setSelectedWorkingHoursOptions(selectedWorkingHoursOptions);
        setWorkingHoursError(fieldErrorLengthCheck(selectedWorkingHoursOptions));
    };
    if (submitStatus === 1) {
        return (
            <div>
                <JobSubmissionSuccess {...props}/>
            </div>
        );
    } else {
        return (
        <div className="job-application-submission border-opacity-25 creatjoblistingform" >
            <div className="title-container">
                <h1 className="title title-job">Job listing submission form</h1>
            </div>
    
            {submitStatus === -1 && <AlertDismissible onClose={() => setSubmitStatus(0)} alertMessage={"Something went wrong when posting your listing. Please try again."}/>}
            <form onSubmit={handleSubmit(handleSubmitClick)}>
                <div className="section form-margin">
                    <div className="subsections">
                        <div className="form-group dropdown-margin">
                            <label for="companyName">Company name <label style={{ color: 'red' }}>*</label></label>
                            <Dropdown className="form-group employer-dropdown" onSelect={handleSelect}>
                                <Dropdown.Toggle className="bootstrap-dropdown" disabled={(!isAdmin)} variant="light" id="dropdown-basic">
                                    {state.companyName || "Company name"}
                                </Dropdown.Toggle>
        
                                <Dropdown.Menu>
                                {listOfCompanies.map((company) => <Dropdown.Item id="companyName" key={company.name}>{company.name}</Dropdown.Item>)}
                                </Dropdown.Menu>
                            </Dropdown>
                            {companyNameError && <span className='error'>Please enter company name becuase it is required.</span>}  
                        </div>
                        <div className="form-group">
                            <label>
                                <label for="title">Job title <label style={{ color: 'red' }}>*</label></label>
                                <BiHelpCircle data-tip="React-tooltip" data-for="title-tooltip"/>
        
                                <ReactTooltip id="title-tooltip" place="right" type="dark" effect="float">
                                    <span>Avoid jargon when designing the job title – amend the job title if needed.</span>
                                </ReactTooltip>
                            </label>
                            <input  className={"form-control"} id="title" placeholder="Job title" value={state.title} {...register("title",{ required: true })}/>
                            {errors.title && <span className='error'>Job title is required.</span>}  
                        </div>
                        <div className="form-group">
                            <label>
                                <label for="careerLevel">Career level <label style={{ color: 'red' }}>*</label></label>
                                <BiHelpCircle data-tip="React-tooltip" data-for="careerLevel-tooltip"/>
        
                                <ReactTooltip id="careerLevel-tooltip" place="right" type="dark" effect="float">
                                    <span>Entry, intermediate, management or senior etc.</span>
                                </ReactTooltip>
                            </label>
                            <input  className={"form-control"} id="careerLevel" placeholder="Career level" value={state.careerLevel} {...register("careerLevel",{ required: true })}/>
                            {errors.careerLevel && <span className='error'>Career level is required.</span>}
                        </div>
                        <div className="form-group">
                            <label>
                                <label for="salary">Salary <label style={{ color: 'red' }}>*</label></label>
                                <BiHelpCircle data-tip="React-tooltip" data-for="Salary-tooltip"/>
        
                                <ReactTooltip id="Salary-tooltip" place="right" type="dark" effect="float">
                                    <span>Avoid ‘negotiable’ or TBC to ensure that you don’t exclude candidates who don’t feel confident to negotiate. As a minimum publish a salary range or offer a 1:1 conversation prior to application.</span>
                                </ReactTooltip>
                            </label>
                            <input className="form-control" id="salary" placeholder="Salary" value={state.salary} {...register("salary",{ required: true })}/>
                            {errors.salary && <span className='error'>Salary is required.</span>}
                        </div>                
                        <div className="form-group dropdown-margin">
                            <label for="jobSectors">Job sectors <label style={{ color: 'red' }}>*</label></label>
                            <Select isMulti={false} placeholder="Select job sectors" options={listOfJobSectors} value={selectedJobSectorsOptions} onChange={handleJobSectorsChange} />
                            {jobSectorsError && <span className='error'>Please select job sectors becuase it is required.</span>}  
                        </div>
                        <div className="form-group dropdown-margin" style={isApplicantRoles? {} : { display: 'none' }}>
                            <label for="rolesAvailable">Job applicant roles<label style={{ color: 'red' }}>*</label></label>
                            <Select isMulti={true} placeholder="Select job applicant roles" options={listOfJobRoles} value={selectedJobRolesOptions} onChange={handleJobRolesChange} closeMenuOnSelect={false}/>
                            {rolesAvailableError && <span className='error'>Please select job applicant roles becuase it is required.</span>} 
                        </div>  
                    </div>
                    <div className="subsections"> 
                        <div className="form-group">
                            <label for="applicationDeadline">Application deadline</label>
                            <DatePicker 
                                className="form-control" 
                                selected={applicationDeadline}
                                minDate={new Date()}
                                onChange={date => {
                                    setApplicationDeadline(date); 
                                }}
                                dateFormat={"dd/MM/yyyy"}
                            />
                        </div>        
                        <div className="form-group">
                            <label for="jobRegions">Regions <label style={{ color: 'red' }}>*</label></label>
                            <Select isMulti={true} placeholder="Select job regions" options={listOfJobRegions} value={selectedJobRegionsOptions} onChange={handleJobRegionsChange} closeMenuOnSelect={false}/>
                            {jobRegionsError && <span className='error'>Please select job regions becuase it is required.</span>} 
                        </div>         
                        <div className="form-group dropdown-margin">
                            <label for="contractType">Contract types <label style={{ color: 'red' }}>*</label></label>
                            <Select isMulti={true} placeholder="Select job contract types" options={listOfContractTypes} value={selectedContractTypesOptions} onChange={handleContractTypesChange} closeMenuOnSelect={false}/>
                            {contractTypeError && <span className='error'>Please select contract types becuase it is required.</span>} 
                        </div>
                        <div className="form-group dropdown-margin">
                            <label for="jobWorkLocations">Job work locations <label style={{ color: 'red' }}>*</label></label>
                            <Select isMulti={true} placeholder="Select job work locations" options={listOfWorkLocations} value={selectedJobWorkLocationsOptions} onChange={handleJobWorkLocationsChange} closeMenuOnSelect={false}/>
                            {jobWorkLocationsError && <span className='error'>Please select job work locations becuase it is required.</span>} 
                        </div>
                        {/*<div className="form-group">
                            <Form.Check>
                                <Form.Check.Input type={'checkbox'} checked={isWorkFromHome} onChange={() => setIsWorkFromHome(!isWorkFromHome)}/>
                                <Form.Check.Label>
                                    Flexible/work from home
                                </Form.Check.Label>
                            </Form.Check>
                        </div>*/}
                        <div className="form-group dropdown-margin">
                            <label for="workingHours">Working hours <label style={{ color: 'red' }}>*</label></label>
                            <Select isMulti={true} placeholder="Select job working hours" options={listOfWorkHours} value={selectedWorkingHoursOptions} onChange={handleWorkingHoursChange} closeMenuOnSelect={false}/>
                            {workingHoursError && <span className='error'>Please select working hours becuase it is required.</span>} 
                        </div>
                        <div className="form-group dropdown-margin">
                            <label>
                                <label for="contact">Contact <label style={{ color: 'red' }}>*</label></label>
                                <BiHelpCircle data-tip="React-tooltip" data-for="contact-tooltip"/>
        
                                <ReactTooltip id="contact-tooltip" place="right" type="dark" effect="float">
                                    <span>We advise that you appoint a key contact as this will help applicants to feel confident about applying and encourage them to effectively disclose at the start of your process.</span>
                                </ReactTooltip>
                            </label>
                            <input  className={"form-control"} id="contact" placeholder="Contact" value={state.contact} {...register("contact",{ required: true })}/>
                            {errors.contact && <span className='error'>Contact is required.</span>}
                        </div>
                    </div>
                </div>
                <div className="sections form-margin">
                    <div className="form-group dropdown-margin">
                        <label for="aboutTheCompany">About the company</label>
                        <RichTextEditor id="aboutCompany" disabled={((isAdmin) ?false : true)} value={state.aboutCompany} onChange={handleACOnChange}/>
                    </div>
                    <div className="form-group dropdown-margin">
                        <label>
                            <label for="companySpecialize">Company specialism</label>
                            <BiHelpCircle data-tip="React-tooltip" data-for="companySpecialize-tooltip"/>
                            <ReactTooltip id="companySpecialize-tooltip" place="right" type="dark" effect="float">
                                <span>What does your company specialise in</span>
                            </ReactTooltip>
                        </label>
                        <RichTextEditor id="companySpecialize" disabled={((isAdmin) ?false : true)} value={state.companySpecialize} onChange={handleCSOnChange}/>
                    </div>
                    <div className="form-group dropdown-margin">
                        <label>
                            <label for="companyPractices">Company practices</label>
                            <BiHelpCircle data-tip="React-tooltip" data-for="companyPractices-tooltip"/>
                            <ReactTooltip id="companyPractices-tooltip" place="right" type="dark" effect="float">
                                <span>What practices do you currently employ for those prospective candidates on the autistic spectrum</span>
                            </ReactTooltip>
                        </label>
                        <RichTextEditor id="companyPractices" disabled={((isAdmin) ?false : true)} value={state.companyPractices} onChange={handleCPOnChange}/>
                    </div>
                    <div className="form-group dropdown-margin">
                        <label>
                            <label for="roleDescription">Role description and benifits<label style={{ color: 'red' }}>*</label></label>
                            <BiHelpCircle data-tip="React-tooltip" data-for="roleDescription-tooltip"/>
            
                            <ReactTooltip id="roleDescription-tooltip" place="right" type="dark" effect="float">
                                <span>Avoid ambiguous language & be clear about communication expectations, phone answering etc. Describe the workplace/culture: creative/lively, or process driven/measured? Use bullets for the main duties. </span>
                            </ReactTooltip>
                        </label>
                        <RichTextEditor id="roleDescription" value={state.roleDescription} onChange={handleRoleOnChange}/>
                        {roleDescriptionError && <span className='error'>Please enter role description becuase it is required.</span>} 
                    </div>
                    <div className="form-group dropdown-margin">
                        <label>
                            <label for="howToApply">How To Apply <label style={{ color: 'red' }}>*</label></label>
                            <BiHelpCircle data-tip="React-tooltip" data-for="howToApply-tooltip"/>
                            <ReactTooltip id="howToApply-tooltip" place="right" type="dark" effect="float">
                                <span>We are waiting for this tooltip content</span>
                            </ReactTooltip>
                        </label>
                        <RichTextEditor id="howToApply" value={state.howToApply} onChange={handleHowToApplyOnChange}/>
                        {howToApplyError && <span className='error'>Please enter how to apply becuase it is required.</span>}
                    </div>
                    <div className="form-group dropdown-margin">
                        <label className="about-team-label">
                        <label className="about-team-label" for="aboutTeam">About the team</label>
                            <BiHelpCircle data-tip="React-tooltip" data-for="aboutTeam-tooltip"/>

                            <ReactTooltip id="aboutTeam-tooltip" place="right" type="dark" effect="float">
                                <span>Please briefly describe the team environment for this position as autistic people might find it helpful when considering applying.</span>
                            </ReactTooltip>
                        </label>
                        <RichTextEditor id="aboutTeam" value={state.aboutTeam} onChange={handleTeamOnChange}/>
                    </div>
                    <div className="form-group dropdown-margin"></div>
                    <div className="form-group dropdown-margin">
                        <label for="listingLink">Link to full job description <label style={{ color: 'red' }}>*</label></label>
                        <input  className={"form-control"} id="link" placeholder="Link to full job description" value={state.link} {...register("listingLink",{ required: true })}/>
                        {errors.listingLink && <span className='error'>Link to full job description is required.</span>}
                    </div>
                    <div className="form-group dropdown-margin"></div>
                    <div>
                        <Button type="submit" title={"Submit"} />
                    </div>
                </div>
            </form>
        </div>
        )
    }
}

function JobSubmissionSuccess(props) {
    const handleDashboardClick = (e) => {
        props.history.push('/my-dashboard');
    }

    return (
        <div className="general-form">
            <div className="submission-status">
                <h1 className="title">Congratulations!</h1>
                <br/>
                <text className="account-state">You have successfully posted a new job listing!</text>
                <br/>
                <text className="account-state">An email has been sent to a NAS admin to review your submission.</text>
            </div>
            <br/>
            <br/>
            <div className="buttons">
                <Button type="submit" title={"Back to dashboard"} onClick={handleDashboardClick}/>
            </div>
        </div>
    )
}

export default withRouter(Job);