import React, { useState, useEffect} from 'react';
import Button from '../../components/Button/Button.js';
import AlertDismissible from '../../components/AlertDismissible/AlertDismissible.js';
import {getCategoryById,updateFaqCategory} from '../../services/FaqsService';
import './Faqs.css';
import { withRouter,useLocation } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { BiHelpCircle } from 'react-icons/bi';

import Dropdown from "react-bootstrap/Dropdown";
import { EMPTY_STRING } from "../../constants/formConstants";

function UpdateFaqCategory(props) {
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [submitStatus, setSubmitStatus] = useState(0);
    const [action, setAction] = useState();
    const [isInactive, setIsInactive] = useState();
    
    const queryParams = useLocation().search;
    const categoryId = new URLSearchParams(queryParams).get('categoryId');

    const [state, setState] = useState({
        name: EMPTY_STRING,
        position: EMPTY_STRING,
        status: EMPTY_STRING,
    })
    var checkFormValid=true;
    useEffect(() => {
        if(categoryId){
            getCategoryById(categoryId).then((response) => {
                setState(prevState => ({
                    ...prevState,
                    name: response.name || "",
                    position: response.position || "",
                    status: (response.status===1)?"Active":"InActive" || ""
                }))
                setIsInactive(0);
            }).catch(function () {
                setSubmitStatus(-1);
            });
        }
      }, [categoryId])

      
    const editCategory = () => {
        setIsReadOnly(false);
    }
    
    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }
    Object.entries(state).forEach(entry => {
        const [key, value] = entry;
        var array = ["answer","status"];
        if(!array.includes(key) && (value==='')){
            checkFormValid=false;
        }
    });
    const formValid=(checkFormValid===true)?true:checkFormValid;

    const cancelChanges = () => {
        setIsReadOnly(true)
    }    
    function handleSubmitClick() {
        const payload = {
            "id": categoryId,
            "name": state.name,
            "position": state.position,
            "status": (state.status==="Active")?"1":"0"
        }
            
        updateFaqCategory(payload).then(function (response) {
            setAction("UPDATEDCONTENTVIEW");
            setSubmitStatus(1);
        })
        .catch(function (error) {
            setSubmitStatus(-1);
        });
    }

    const handleSelect = (e, value) => {
        const { id, text } = value.target
        setState(prevState => ({
            ...prevState,
            [id]: text
        }))
    } 
    const backContentList = (e) => {
        props.history.push('/faq-categories');
    }
    
    if (submitStatus === 1) {
        return (
            <div>
                <UpdateContentSuccess action={action} name={state.name} {...props}/>
            </div>
        );
    } else {
        return (
            <div className="faqs-application-submission border-opacity-25">
                <div className="title-container">
                    <Button type="submit" title={"Back"} onClick={backContentList}><i class="fa fa-arrow-left" aria-hidden="true"></i></Button>
                    <h1 className="title title-job">{state.name} Faq Category</h1>
                    {!isInactive && <Button type="submit" disabled={false} title={"Edit"} onClick={editCategory}/>}
                </div>

                {submitStatus === -1 && <AlertDismissible onClose={() => setSubmitStatus(0)} alertMessage={"Something went wrong when posting your listing. Please try again."}/>}
                <div className="form-margin">
                    <div className="form-group">
                        <label>
                            <label for="name">Name <label style={{ color: 'red' }}>*</label></label>
                            <BiHelpCircle data-tip="React-tooltip" data-for="name-tooltip"/>
    
                            <ReactTooltip id="name-tooltip" place="right" type="dark" effect="float">
                                <span>Please update category name. if you want to edit</span>
                            </ReactTooltip>
                        </label>
                        <input  className={"form-control"} id="name" placeholder="Category name" value={state.name} onChange={handleChange} disabled={isReadOnly} required/>
                    </div>
                    <div className="form-group">
                        <label>
                            <label for="position">Position <label style={{ color: 'red' }}>*</label></label>
                            <BiHelpCircle data-tip="React-tooltip" data-for="position-tooltip"/>
    
                            <ReactTooltip id="position-tooltip" place="right" type="dark" effect="float">
                                <span>Please update category position. if you want to edit</span>
                            </ReactTooltip>
                        </label>
                        <input  className={"form-control"} id="position" placeholder="Category position" value={state.position} onChange={handleChange} disabled={isReadOnly} required/>
                    </div>
                    <div className="form-group dropdown-margin">
                        <label for="status">Status <label style={{ color: 'red' }}>*</label></label>
                        <Dropdown className="form-group employer-dropdown" onSelect={handleSelect}>
                            <Dropdown.Toggle className="bootstrap-dropdown" variant="light" id="dropdown-basic" disabled={isReadOnly}>
                                {state.status || "InActive"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item id="status">Active</Dropdown.Item>
                                <Dropdown.Item id="status">InActive</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div>
                        <Button type="submit" disabled={!formValid} title={"Submit"} onClick={() => handleSubmitClick()}/>
                        {!isReadOnly && <Button type="submit" title={"Cancel"} onClick={cancelChanges}/>}
                    </div>
                </div>
            </div>
        )
    }
}

function UpdateContentSuccess({action, question, ...props}) {
    const handleFaqClick = (e) => {
        props.history.push('/faq-categories');
    }

    if (action === "UPDATEDCONTENTVIEW") {
        return (
            <div className="general-form">
                <div className="submission-status">
                    <h1 className="title">{question} Success!</h1>
                    <br/>
                    <text className="account-state">You have successfully updated your faq category!</text>
                </div>
                <br/>
                <br/>
                <div className="buttons">
                    <Button type="submit" disabled={false} title={"Back to faqs"} onClick={handleFaqClick}/>
                </div>
            </div>
        )
    }
}

export default withRouter(UpdateFaqCategory);