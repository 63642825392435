import DefaultDashboard from '../../pages/DefaultDashboard/DefaultDashboard';
import LoginForm from '../../pages/LoginForm/LoginForm';
import {ADMIN, SUPER_ADMIN, LOGGED_OUT} from "../../constants/userConstants";
import EmployerSignUp from '../../pages/EmployerSignUp/EmployerSignUp';
import ForgotPassword from '../../pages/ForgotPassword/ForgotPassword';
import ResetPassword from '../../pages/ResetPassword/ResetPassword';
import User from '../../pages/User/User';
import Report from '../../pages/Report/Report';
import Job from '../../pages/Job/Job';
import JobApplicationSubm from '../../pages/JobApplicationSubm/JobApplicationSubm';
import AddEmployer from '../../pages/AddEmployer/AddEmployer';
import Profile from '../../pages/Profile/Profile';
import AddAdmin from '../../pages/AddAdmin/AddAdmin';
import ConsentingUsers from '../../pages/ConsentingUsers/ConsentingUsers';
import Employers from '../../pages/Employers/Employers';
import ContentPages from '../../pages/ContentPages/ContentPages';
import ContentPageEdit from '../../pages/ContentPages/ContentPageEdit';
// import Faqs from '../../pages/Faqs/Faqs';
import FaqQuestions from '../../pages/Faqs/FaqQuestions';
import UpdateQuestion from '../../pages/Faqs/UpdateQuestion';
import CreateQuestion from '../../pages/Faqs/CreateQuestion';
import FaqCategories from '../../pages/Faqs/FaqCategories';
import CreateFaqCategory from '../../pages/Faqs/CreateFaqCategory';
import UpdateFaqCategory from '../../pages/Faqs/UpdateFaqCategory';



import {
    Route,
    Redirect
  } from "react-router-dom";

const adminRoutes = [
    {path: "/my-dashboard", Component: DefaultDashboard},
    {path: "/user", Component: User},
    {path: "/job", Component: Job},
    {path: "/job-application-submission", Component: JobApplicationSubm},
    {path: "/my-profile", Component: Profile},
    {path: "/add-admin", Component: AddAdmin}
]

const routes = {
    [ADMIN]: adminRoutes,
    [SUPER_ADMIN]: [
        ...adminRoutes, 
        {path: "/report", Component: Report},
        {path: "/consenting-candidates", Component: ConsentingUsers},
        {path: "/add-employer", Component: AddEmployer},
        {path: "/employers", Component: Employers},
        {path: "/content-pages", Component: ContentPages},
        {path: "/content-pages-edit", Component: ContentPageEdit},
        {path: "/faq-categories", Component: FaqCategories},
        {path: "/create-faq-category", Component: CreateFaqCategory},
        {path: "/update-faq-category", Component: UpdateFaqCategory},
        // {path: "/faqs", Component: Faqs},
        {path: "/faq-questions", Component: FaqQuestions},
        {path: "/create-faq-question", Component: CreateQuestion},
        {path: "/update-faq-question", Component: UpdateQuestion},
    ],
    [LOGGED_OUT]: [
        {path: "/login", Component: LoginForm},
        {path: "/sign-up", Component: EmployerSignUp},
        { path: "/forgot-password", Component: ForgotPassword },
        { path: "/reset-password", Component: ResetPassword }
    ]
}

function Routes(props) {
    const coreRoutes = routes[props.role];
    return [
        ...coreRoutes.map(({path, Component}) => 
            <Route path={path}>
                <Component {...props}/>
            </Route>
        ),
        <Route path="/">
            <Redirect to={coreRoutes[0].path}/>
        </Route>
    ]
}

export default Routes;