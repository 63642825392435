import axios from "axios";
import { LOGGED_OUT } from "../constants/userConstants";
import FileDownload from "js-file-download";

import {
  API_BASE_URL,
  API_URL_EXTENSION,
  ACCESS_TOKEN_NAME,
} from "../constants/apiConstants";

function deleteUser(userId) {
  const url = API_BASE_URL + API_URL_EXTENSION + "/user/deleteUser?userId=" + userId;

  return axios
    .delete(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return true;
    })
    .catch(function (error) {
      return false;
    });
}

function getAuthenticatedUser() {
  const url =
    API_BASE_URL + API_URL_EXTENSION + "/user/authenticatedUser";

  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return {access: LOGGED_OUT};
    });
}

function addNewEmployer(payload) {
  const url =
    API_BASE_URL + API_URL_EXTENSION + "/employer/create";
    return axios.post(url, payload, {'headers': {'Authorization':localStorage.getItem(ACCESS_TOKEN_NAME)}})
        .then(function(response) {
            return response.data
        }).catch(function(error) {
            return null;
        });
}

function postAddAdmin(companyRole, contactFirstName, contactLastName, contactEmail) {
  const url = API_BASE_URL + API_URL_EXTENSION + '/user/createAdminUserForNAS';

  const body = {
    companyRole: companyRole,
    contactFirstName: contactFirstName,
    contactLastName: contactLastName,
    contactEmail: contactEmail,
  };

  return axios.post(url, body, { 'headers': { 'Authorization': localStorage.getItem(ACCESS_TOKEN_NAME) } })
    .then(function (response) {
      return response.data
    })
}

function getEmployer(employerId) {
  const url =
    API_BASE_URL +
    API_URL_EXTENSION +
    "/employer/getEmployerById?employerId=" +
    employerId;
    
  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return response.data;
    });
}

function getNewUsers() {
  const url =
    API_BASE_URL + API_URL_EXTENSION + "/employerSignUp/allSignUpFormOpen";

  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return response.data;
    });
}

function getNewUser(userId) {
  const url =
    API_BASE_URL +
    API_URL_EXTENSION +
    "/employerSignUp/getEmployerSignUpById?employerSignUpId=" +
    userId;

  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return {};
    });
}

function getConsentingCandidates() {
  const url =
    API_BASE_URL +
    API_URL_EXTENSION +
    "/candidate/all-consenting";

  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return {};
    });
}

function putNewUserApproval(id, companyName, companyId, companyWebsite, aboutCompany, requestOn, status, companyRole, contactFirstName, contactLastName, contactEmail, password) {
  const url = API_BASE_URL + API_URL_EXTENSION + `/employerSignUp/${id}`;
  const payload = {
      "id": id,
      "companyName": companyName,
      "companyId": companyId,
      "companyWebsite": companyWebsite,
      "aboutCompany": aboutCompany,
      "requestOn": requestOn,
      "status": status,
      "companyRole": companyRole,
      "contactFirstName": contactFirstName,
      "contactLastName": contactLastName,
      "contactEmail": contactEmail,
      "password": password,
  }

  return axios.put(
    url, 
    payload, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function(response) {
      return response.data
    });
}

function downloadCandidatesToCsv() {
  let url =
    API_BASE_URL +
    API_URL_EXTENSION +
    "/candidate/downloadAsCsv";

  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
      responseType: "blob",
    })
    .then(function (response) {
      console.log(response);
      FileDownload(response.data, "candidates-report.csv");
    })
    .catch(function (error) {
      return [];
    });
}

function getUsers() {
  const url =
  API_BASE_URL +
  API_URL_EXTENSION +
  "/user/all";
  
return axios
  .get(url, {
    headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
  })
  .then(function (response) {
    return response.data;
  });
}

export { getAuthenticatedUser, addNewEmployer, postAddAdmin, putNewUserApproval, getNewUsers, getNewUser, getEmployer, deleteUser, getConsentingCandidates, downloadCandidatesToCsv, getUsers }