import axios from 'axios';
import { API_BASE_URL, API_URL_EXTENSION, ACCESS_TOKEN_NAME } from '../constants/apiConstants';

function postEmployerSignUp(companyName, companyWebsite, aboutCompany, companyRole,
    contactFirstName, contactLastName, contactEmail, contactPassword) {
    const url = API_BASE_URL + API_URL_EXTENSION + '/employerSignUp/create';

    console.log(contactPassword)

    const body = {
        companyName: companyName,
        companyId: null,
        companyWebsite: companyWebsite,
        aboutCompany: aboutCompany,
        companyRole: companyRole,
        contactFirstName: contactFirstName,
        contactLastName: contactLastName,
        contactEmail: contactEmail,
        password: contactPassword
    };

    return axios.post(url, body, {'headers': {'Authorization':localStorage.getItem(ACCESS_TOKEN_NAME)}})
        .then(function(response) {
            return response.data
        })
}

function deleteEmployerSignUp(signUpId) {
  const url = API_BASE_URL + API_URL_EXTENSION + "/employerSignUp/delete?employerSignUpId=" + signUpId;

  return axios
    .delete(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return true;
    })
    .catch(function (error) {
      return false;
    });
}

function postLogin(email, password) {
  const auth_url = API_BASE_URL + "/authenticate";
  const payload = {
    username: email,
    password: password,
  };

  return axios.post(auth_url, payload, {'headers': {'Content-Type':'application/json', 'withCredentials' : 'true'}})
        .then(function(response) {
            return response.data
        }).catch(function(error) {
            return null;
        });
}

function postForgotPassword(email) {
    const url = API_BASE_URL + API_URL_EXTENSION + '/user/forgottenPassword?username=' + email;

  return axios.post(url, { 'headers': { 'Content-Type': 'application/json', 'withCredentials': 'true' } })
        .then(function (response) {
            return response.data
        }).catch(function (error) {
            return null;
        });
}

function postValidateResetToken(email, token) {
  const url = API_BASE_URL + API_URL_EXTENSION + '/user/validateResetToken?resetToken=' + token;

  return axios.post(url, { 'headers': { 'Content-Type': 'application/json', 'withCredentials': 'true' } })
        .then(function (response) {
            return response.data
        }).catch(function (error) {
            return null;
        });
}

function postResetPassword(token, password) {
  const url = API_BASE_URL + API_URL_EXTENSION + '/user/resetPassword';
    const payload = {
        "resetToken": token,
        "password": password,
    }

  return axios.post(url, payload, { 'headers': { 'Content-Type': 'application/json', 'withCredentials': 'true' } })
        .then(function (response) {
            return response.data
        }).catch(function (error) {
            return null;
        });
}

function getAllEmployers() {
  const url = API_BASE_URL + API_URL_EXTENSION + "/employer/all";

  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    });
}

export {
  deleteEmployerSignUp, postEmployerSignUp, postLogin, postForgotPassword, postValidateResetToken, postResetPassword, getAllEmployers };