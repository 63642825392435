import React, { useState } from 'react';
import './AddAdmin.css';
import { withRouter } from "react-router-dom";
import SubmitStatus from './SubmitStatus';
import Form from './Form';

function AddAdmin(props) {

    const [submitStatus, setSubmitStatus] = useState(0);

    return (
        <div className="general-form add-admin-form border-opacity-25">
            {submitStatus === 1
                ? <SubmitStatus {...props}/>
                : <Form status={submitStatus} onFormSubmit={setSubmitStatus} {...props}/>
            }
        </div>
    )
}

export default withRouter(AddAdmin);