import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { withRouter } from "react-router-dom";
import { postValidateResetToken } from '../../services/LoginService';
import SubmitStatusAdmin from './SubmitStatusAdmin';
import SubmitStatusCandidate from './SubmitStatusCandidate';
import Button from '../../components/Button/Button.js';
import Form from './Form';

function ResetPassword(props) {

    const [submitStatus, setSubmitStatus] = useState(0);
    
    const TokenStatus = { 
        Validating: 'Validating',
        Valid: 'Valid',
        Invalid: 'Invalid'
    }

    const [userType, setUserType] = useState(null);
    const [token, setToken] = useState(null);
    const [tokenStatus, setTokenStatus] = useState(TokenStatus.Validating);

    useEffect(() => {
        const { token } = queryString.parse(props.location.search)

        postValidateResetToken(props.email, token)
            .then(({ type }) => {
                setUserType(type);
                setToken(token);
                setTokenStatus(TokenStatus.Valid);
            })
            .catch(() => {
                setTokenStatus(TokenStatus.Invalid);
            });
    }, [TokenStatus.Invalid,TokenStatus.Valid,props.email,props.location.search]);

    const redirectToForgotPassword = (e) => {
        e.preventDefault()
        props.history.push('/forgot-password');
    }

    if(tokenStatus === TokenStatus.Valid) {
        return (
            <div className="general-form">
                {submitStatus === 1
                    ? 
                    (userType === 'candidate' 
                        ? <SubmitStatusCandidate {...props} />
                        : <SubmitStatusAdmin {...props} />
                    )
                    : <Form status={submitStatus} token={token} onFormSubmit={setSubmitStatus} {...props} />
                }
            </div>    
            )
    } else if (tokenStatus === TokenStatus.Validating) {
        return (
            <div>Validating token...</div>
        )
    } else {
        return (
            <div className="error">
                <h1 className="title">Your password reset token is invalid.</h1>
                <br />
                <div>Please ensure that you have followed the correct link from the email you recieved.</div>
                <div>If you still recieve this error, please fill in the forgotten password form again.</div>
                <br />
                <div>
                    <br />
                    <Button type={"submit"} title={"Forgot Password"} onClick={redirectToForgotPassword} />
                </div>
            </div>
        )
    }
}

export default withRouter(ResetPassword);