import React, { useState, useEffect } from 'react';
import Button from '../../components/Button/Button.js';
import AlertDismissible from '../../components/AlertDismissible/AlertDismissible.js';
import {getAllEmployers} from '../../services/LoginService.jsx';
import { getJob, deleteListing, putJobListing,optionsToCommaString,getAllPreferences,commaStringToOptions,commaStringToOption,getAllActiveSectorJobRoles} from '../../services/JobService.jsx';
import { withRouter } from "react-router-dom";
import './Job.css';
import ReactTooltip from 'react-tooltip';
import { BiHelpCircle } from 'react-icons/bi';
import Dropdown from "react-bootstrap/Dropdown";
/*import Form from "react-bootstrap/Form";*/
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { EMPTY_STRING } from "../../constants/formConstants";
import {useLocation} from "react-router-dom";
import { SUPER_ADMIN } from '../../constants/userConstants';
import Select from 'react-select';
import RichTextEditor from 'react-rte';
 
function Job(props) {
    const [applicationDeadlineDate, setApplicationDeadlineDate] = useState();
    const [listOfCompanies, setListOfCompanies] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [submitStatus, setSubmitStatus] = useState(0);
    const [action, setAction] = useState();
    const [salary, setSalary] = useState();
    /*const [isWorkFromHome, setIsWorkFromHome] = useState(false);*/
    const [isInactive, setIsInactive] = useState();
    const [backupDate, setBackupDate] = useState();
    const [jobStatus, setJobStatus] = useState();
    const [createdDate, setCreatedDate] = useState(); 
    const [listOfJobRoles, setListOfJobRoles] = useState([]);
    const [listOfJobRegions, setListOfJobRegions] = useState([]);
    const [listOfJobSectors, setListOfJobSectors] = useState([]);
    const [listOfContractTypes, setListOfContractTypes] = useState([]);
    const [listOfWorkLocations, setListOfWorkLocations] = useState([]);
    const [listOfWorkHours, setListOfWorkHours] = useState([]);

    const [selectedJobRolesOptions,setSelectedJobRolesOptions]= useState("");
    const [selectedContractTypesOptions,setSelectedContractTypesOptions]= useState("");
    const [selectedJobSectorsOptions,setSelectedJobSectorsOptions]= useState("");
    const [selectedJobRegionsOptions,setSelectedJobRegionsOptions]= useState("");
    const [selectedJobWorkLocationsOptions,setSelectedJobWorkLocationsOptions]= useState("");
    const [selectedWorkingHoursOptions,setSelectedWorkingHoursOptions]= useState("");
    
    const queryParams = useLocation().search;
    const id = new URLSearchParams(queryParams).get('id');

    const [state, setState] = useState({
        companyName: EMPTY_STRING,
        title: EMPTY_STRING,
        careerLevel: EMPTY_STRING,
        contact: EMPTY_STRING,
        link: EMPTY_STRING,
        aboutCompany: RichTextEditor.createEmptyValue(),
        aboutTeam: RichTextEditor.createEmptyValue(),
        roleDescription: RichTextEditor.createEmptyValue(),
        howToApply: RichTextEditor.createEmptyValue(),
        companySpecialize: RichTextEditor.createEmptyValue(),
        companyPractices: RichTextEditor.createEmptyValue(),
    })

    const [backupState, setBackupState] = useState({
        companyName: EMPTY_STRING,
        title: EMPTY_STRING,
        careerLevel: EMPTY_STRING,
        salary: EMPTY_STRING,
        applicationDeadline: undefined,
        /*isWorkFromHome: undefined,*/
        contact: EMPTY_STRING,
        link: EMPTY_STRING,
        aboutCompany: RichTextEditor.createEmptyValue(),
        aboutTeam: RichTextEditor.createEmptyValue(),
        roleDescription: RichTextEditor.createEmptyValue(),
        howToApply: RichTextEditor.createEmptyValue(),
        companySpecialize: RichTextEditor.createEmptyValue(),
        companyPractices: RichTextEditor.createEmptyValue(),
    })
    useEffect(() => {   
        const loadJobEditSection = async () => {
            try {
                const getAllEmployersResoponse=await getAllEmployers();
                if((Object.keys(getAllEmployersResoponse).length>0)){
                    if (props.role === SUPER_ADMIN) {
                        setIsAdmin(true);
                        setListOfCompanies(getAllEmployersResoponse);
                    } else {
                        setListOfCompanies([getAllEmployersResoponse[0].name]);
                        setState(prevState => ({
                            ...prevState,
                            companyName: getAllEmployersResoponse[0].name,
                        }))
                    }
                }else {
                    setSubmitStatus(-1);
                }; 
                const getAllPreferenceResoponse=await getAllPreferences('preferences');
                const getJobResponse=await getJob(id);
                setState(prevState => ({
                    ...prevState,
                    companyName: getJobResponse.companyName || "",
                    title: getJobResponse.title || "",
                    careerLevel: getJobResponse.qualification || "",
                    aboutTeam: RichTextEditor.createValueFromString(getJobResponse.teamInfo, 'html') || "",
                    contact: getJobResponse.contactInfo || "",
                    link: getJobResponse.applicationLink || "",
                    roleDescription:RichTextEditor.createValueFromString(getJobResponse.description, 'html') || "",
                    howToApply:RichTextEditor.createValueFromString(getJobResponse.howToApply, 'html') || "",
                    aboutCompany: RichTextEditor.createValueFromString(getJobResponse.companyInfo, 'html') || "",
                    companySpecialize: RichTextEditor.createValueFromString(getJobResponse.companySpecialize, 'html') || "",
                    companyPractices: RichTextEditor.createValueFromString(getJobResponse.companyPractices, 'html') || ""
                }));
                setSalary(getJobResponse.salary);
                setBackupDate(getJobResponse.deadline || undefined);
                /*setIsWorkFromHome(getJobResponse.flexible || false);*/
                setJobStatus(getJobResponse.status);
                setCreatedDate(getJobResponse.createdDate);
                setApplicationDeadlineDate(getJobResponse.deadline === null ? undefined : new Date(getJobResponse.deadline));
        
                setIsInactive(getJobResponse.status === "INACTIVE");
        
                let preferencesResp=getAllPreferenceResoponse;
                if(preferencesResp.hasOwnProperty("job_roles")){
                    /** Set Job Roles on the basis of sector */
                    /** Get Job Roles */
                    getAllActiveSectorJobRoles(getJobResponse.jobSectors).then((response) => {
                        if(Object.keys(response).length>0){
                            setListOfJobRoles(response);
                        } 
                    }).catch(function () {
                        setListOfJobRoles([]);
                    });
                    /*setListOfJobRoles(preferencesResp["job_roles"]);*/
                    if(getJobResponse.rolesAvailable){
                        setSelectedJobRolesOptions(commaStringToOptions(getJobResponse.rolesAvailable,preferencesResp["job_roles"]));
                    }
                }
                if(preferencesResp.hasOwnProperty("job_sectors")){
                    setListOfJobSectors(preferencesResp["job_sectors"]);
                    if(getJobResponse.jobSectors){
                        setListOfJobSectors(preferencesResp["job_sectors"]);
                        setSelectedJobSectorsOptions(commaStringToOption(getJobResponse.jobSectors,preferencesResp["job_sectors"]));
                    }
                }
                if(preferencesResp.hasOwnProperty("job_regions")){
                    setListOfJobRegions(preferencesResp["job_regions"]);
                    if(getJobResponse.jobRegions){
                        setListOfJobRegions(preferencesResp["job_regions"]);
                        setSelectedJobRegionsOptions(commaStringToOptions(getJobResponse.jobRegions,preferencesResp["job_regions"]));
                    }
                }
                if(preferencesResp.hasOwnProperty("job_contract_types")){
                    setListOfContractTypes(preferencesResp["job_contract_types"]);
                    if(getJobResponse.contractType){
                        setSelectedContractTypesOptions(commaStringToOptions(getJobResponse.contractType,preferencesResp["job_contract_types"]));
                    }
                }
                if(preferencesResp.hasOwnProperty("job_work_locations")){
                    setListOfWorkLocations(preferencesResp["job_work_locations"]);
                    if(getJobResponse.workingLocations){
                        setSelectedJobWorkLocationsOptions(commaStringToOptions(getJobResponse.workingLocations,preferencesResp["job_work_locations"]));
                    }
                }
                if(preferencesResp.hasOwnProperty("job_work_hours")){
                    setListOfWorkHours(preferencesResp["job_work_hours"]);
                    if(getJobResponse.workingHours){
                        setSelectedWorkingHoursOptions(commaStringToOptions(getJobResponse.workingHours,preferencesResp["job_work_hours"]));
                    }
                }
            } catch (error) {
              console.log(error);
            }
        };
        loadJobEditSection();     
        
    }, [id,props.role])

    const handleSelect = (e, value) => {
        const { id, text } = value.target
        setState(prevState => ({
            ...prevState,
            [id]: text
        }))

        if (value.target.id === "companyName") {
            const companyMInfo=listOfCompanies.filter((company) => (company["name"] === value.target.text));
            setState(prevState => ({
                ...prevState,
                aboutCompany: RichTextEditor.createValueFromString(companyMInfo[0]["aboutCompany"], 'html'),
                companySpecialize: RichTextEditor.createValueFromString(companyMInfo[0].companySpecialize, 'html') || "",
                companyPractices: RichTextEditor.createValueFromString(companyMInfo[0].companyPractices, 'html') || ""
            }))
        }
    } 
    const editJob = () => {
        setIsReadOnly(false);
        setBackupState({
            ...state,
            salary,
            applicationDeadlineDate
            /*isWorkFromHome*/
        });
        //setApplicationDeadlineDate();
    }

    const cancelChanges = () => {
        setIsReadOnly(true)
        setState(backupState);
        setSalary(backupState.salary);
        /*setIsWorkFromHome(backupState.isWorkFromHome);*/
        setApplicationDeadlineDate(backupDate === undefined ? undefined : new Date(backupDate));
    }

    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }
    
    const handleTeamOnChange = (value) => {
        setState(prevState => ({
            ...prevState,
            aboutTeam:value
        }))
        if (props.OnChange) {
            props.OnChange(
                value.toString('html')
            );
        }
    };
    const handleRoleOnChange = (value) => {
        setState(prevState => ({
            ...prevState,
            roleDescription:value
        }))
        if (props.OnChange) {
            props.OnChange(
                value.toString('html')
            );
        }
    };
    const handleHowToApplyOnChange = (value) => {
        setState(prevState => ({
            ...prevState,
            howToApply:value
        }))
        if (props.OnChange) {
            props.OnChange(
                value.toString('html')
            );
        }
    };
    const handleACOnChange = (value) => {
        setState(prevState => ({
            ...prevState,
            aboutCompany:value
        }))
        if (props.OnChange) {
            props.OnChange(
                value.toString('html')
            );
        }
    };

    const handleCSOnChange = (value) => {
        setState(prevState => ({
            ...prevState,
            companySpecialize:value
        }))
        if (props.OnChange) {
            props.OnChange(
                value.toString('html')
            );
        }
    };
    const handleCPOnChange = (value) => {
        setState(prevState => ({
            ...prevState,
            companyPractices:value
        }))
        if (props.OnChange) {
            props.OnChange(
                value.toString('html')
            );
        }
    };
    
    var checkFormValid=true;
    Object.entries(state).forEach(entry => {
        const [key, value] = entry;
        var array = ["roleDescription","howToApply", "aboutTeam","aboutCompany","companySpecialize","companyPractices"];
        if(!array.includes(key) && (value==='')){
            checkFormValid=false;
        }
    });
    const selectionFields = {'jobRoles':selectedJobRolesOptions,'contractTypes':selectedContractTypesOptions,'jobRegions':selectedJobRegionsOptions,'jobWorkLocations':selectedJobWorkLocationsOptions,'workingHours':selectedWorkingHoursOptions,'jobSectors':[selectedJobSectorsOptions]};
    const checkSelectFieldValid = Object.values(selectionFields).every(v => v.length);
    const formValid=(checkFormValid===true)?checkSelectFieldValid:checkFormValid;

    function handleSubmitClick(status) {
        const selectedjobRolesOption=optionsToCommaString(selectedJobRolesOptions);
        /*const selectedjobSectorsOption=optionsToCommaString(selectedJobSectorsOptions);*/
        const selectedjobSectorsOption=(Object.keys(selectedJobSectorsOptions).length>0)?selectedJobSectorsOptions.value:"";
        const selectedjobRegionsOption=optionsToCommaString(selectedJobRegionsOptions);
        const selectedContractTypesOption=optionsToCommaString(selectedContractTypesOptions);
        const selectedJobWorkLocationsOption=optionsToCommaString(selectedJobWorkLocationsOptions);
        const selectedWorkingHoursOption=optionsToCommaString(selectedWorkingHoursOptions);
        const payload = {
            "id": id,
            "companyName": state.companyName,
            "title": state.title,
            "qualification": state.careerLevel,
            "salary": salary,
            "deadline": applicationDeadlineDate,
            "workingHours":selectedWorkingHoursOption,
            "contractType":selectedContractTypesOption,
            "city": "",
            /*"flexible": isWorkFromHome,*/
            "rolesAvailable": selectedjobRolesOption,
            "companyInfo": state.aboutCompany.toString('html'),
            "teamInfo": state.aboutTeam.toString('html'),
            "contactInfo": state.contact,
            "applicationLink": state.link,
            "description": state.roleDescription.toString('html'),
            "createdDate": "",
            "status": status,       
            "jobSectors": selectedjobSectorsOption,          
            "jobRegions": selectedjobRegionsOption,       
            "workingLocations":selectedJobWorkLocationsOption,
            "companySpecialize": state.companySpecialize.toString('html'),
            "companyPractices": state.companyPractices.toString('html'),
            "howToApply": state.howToApply.toString('html')
        }
        console.log(payload);   
        putJobListing(payload).then(function (response) {
            setAction(status);
            setSubmitStatus(1);
        })
        .catch(function (error) {
            setSubmitStatus(-1);
        });
    }
    const handleDeleteClick = (e) => {
        e.preventDefault();
        if (window.confirm("Are you sure you want to delete this listing?")) {
            deleteListing(id).then(function (response) {
                setAction("delete");
                setSubmitStatus(1);
            })
            .catch(function (error) {
                setSubmitStatus(-1);
            });
        } else {
            window.location.reload();
        }
    }

    /** Job Sectors */
    const handleJobSectorsChange = selectedJobSectorsOptions => {
        setSelectedJobSectorsOptions(selectedJobSectorsOptions);
    
        /** Set Job Roles on the basis of sector */
        /** Get Job Roles */
        getAllActiveSectorJobRoles(selectedJobSectorsOptions.value).then((response) => {
            if(Object.keys(response).length>0){
                setSelectedJobRolesOptions("");
                setListOfJobRoles(response);
            } 
        }).catch(function () {
            //setListOfJobRoles([]);
        });
    };
    /** Job Roles */
    const handleJobRolesChange = selectedJobRolesOptions => {
        setSelectedJobRolesOptions(selectedJobRolesOptions);
    };
    /** Job Regions */
    const handleJobRegionsChange = selectedJobRegionsOptions => {
        setSelectedJobRegionsOptions(selectedJobRegionsOptions);
    };
    /** Job ContractType */
    const handleContractTypesChange = selectedContractTypesOptions => {
        setSelectedContractTypesOptions(selectedContractTypesOptions);
    };
    /** Job JobWorkLocations */
    const handleJobWorkLocationsChange = selectedJobWorkLocationsOptions => {
        setSelectedJobWorkLocationsOptions(selectedJobWorkLocationsOptions);
    };
    /** Job WorkingHours */
    const handleWorkingHoursChange = selectedWorkingHoursOptions => {
        setSelectedWorkingHoursOptions(selectedWorkingHoursOptions);
    };
    if (submitStatus === 1) {
        return (
            <div>
                <JobSubmissionSuccess action={action} companyName={state.companyName} {...props}/>
            </div>
        );
    } else {
        return (
        <div className="job-application-submission border-opacity-25 creatjoblistingform">
            <div className="title-container">
                <h1 className="title title-job">Job listing submission form</h1>
                {!isInactive && <Button type="submit" disabled={false} title={"Edit"} onClick={editJob}/>}
            </div>
    
            {submitStatus === -1 && <AlertDismissible onClose={() => setSubmitStatus(0)} alertMessage={"Something went wrong when posting your listing. Please try again."}/>}
            <div className="section form-margin">
                <div className="subsections">
                    <div className="form-group dropdown-margin">
                        <label for="companyName">Company name</label>
                        <Dropdown className="form-group employer-dropdown" onSelect={handleSelect}>
                            <Dropdown.Toggle className="bootstrap-dropdown" disabled={((!isReadOnly && isAdmin) ? false : true)} variant="light" id="dropdown-basic">
                                {state.companyName}
                            </Dropdown.Toggle>
    
                            <Dropdown.Menu>
                            {listOfCompanies.map((company) => <Dropdown.Item id="companyName">{company.name}</Dropdown.Item>)}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="form-group">
                        <label>
                            <label for="title">Job title <label style={{ color: 'red' }}>*</label></label>
                            <BiHelpCircle data-tip="React-tooltip" data-for="title-tooltip"/>
    
                            <ReactTooltip id="title-tooltip" place="right" type="dark" effect="float">
                                <span>Avoid jargon when designing the job title – amend the job title if needed.</span>
                            </ReactTooltip>
                        </label>
                        <input  className={"form-control"} disabled={isReadOnly} id="title" placeholder="Job title" value={state.title} onChange={handleChange} required/>
                    </div>
                    <div className="form-group">
                        <label>
                            <label for="careerLevel">Career level <label style={{ color: 'red' }}>*</label></label>
                            <BiHelpCircle data-tip="React-tooltip" data-for="careerLevel-tooltip"/>
    
                            <ReactTooltip id="careerLevel-tooltip" place="right" type="dark" effect="float">
                                <span>Entry, intermediate, management or senior etc.</span>
                            </ReactTooltip>
                        </label>
                        <input  className={"form-control"} disabled={isReadOnly} id="careerLevel" placeholder="Career level" value={state.careerLevel} onChange={handleChange} required/>
                    </div>
                    <div className="form-group">
                        <label>
                            <label for="salary">Salary</label>
                            <BiHelpCircle data-tip="React-tooltip" data-for="Salary-tooltip"/>
    
                            <ReactTooltip id="Salary-tooltip" place="right" type="dark" effect="float">
                                <span>Avoid ‘negotiable’ or TBC to ensure that you don’t exclude candidates who don’t feel confident to negotiate. As a minimum publish a salary range or offer a 1:1 conversation prior to application.</span>
                            </ReactTooltip>
                        </label>
                        <input className="form-control" disabled={isReadOnly} id="salary" placeholder="Salary" value={salary} onChange={(e) => setSalary(e.target.value)}/>
                    </div>   
                    <div className="form-group dropdown-margin">
                        <label for="jobSectors">Job sectors <label style={{ color: 'red' }}>*</label></label>
                        <Select isMulti={false} placeholder="Select job sectors" options={listOfJobSectors} value={selectedJobSectorsOptions} onChange={handleJobSectorsChange} isDisabled={isReadOnly} />
                    </div> 
                    <div className="form-group dropdown-margin">
                        <label for="rolesAvailable">Job applicant roles<label style={{ color: 'red' }}>*</label></label>
                        <Select isMulti={true} placeholder="Select job applicant roles" options={listOfJobRoles} value={selectedJobRolesOptions} onChange={handleJobRolesChange} isDisabled={isReadOnly} closeMenuOnSelect={false} />
                    </div>  
                </div>
    
                <div className="subsections">
                    <div className="form-group">
                        <label for="applicationDeadline">Application deadline
                        </label>
                        <DatePicker 
                            className="form-control" 
                            selected={applicationDeadlineDate}
                            onChange={date => {
                                setApplicationDeadlineDate(date); 
                            }}
                            dateFormat={"dd/MM/yyyy"}
                            required
                            disabled = {isReadOnly}
                        />
                    </div>      
                    <div className="form-group">
                        <label for="jobRegions">Regions <label style={{ color: 'red' }}>*</label></label>
                        <Select isMulti={true} placeholder="Select job regions" options={listOfJobRegions} value={selectedJobRegionsOptions} onChange={handleJobRegionsChange} isDisabled={isReadOnly} closeMenuOnSelect={false}/>
                    </div>         
                    <div className="form-group dropdown-margin">
                        <label for="contractType">Contract types <label style={{ color: 'red' }}>*</label></label>
                        <Select isMulti={true} placeholder="Select job contract types" options={listOfContractTypes} value={selectedContractTypesOptions} onChange={handleContractTypesChange} isDisabled={isReadOnly} closeMenuOnSelect={false}/>
                    </div>
                    <div className="form-group dropdown-margin">
                        <label for="jobWorkLocations">Job work locations <label style={{ color: 'red' }}>*</label></label>
                        <Select isMulti={true} placeholder="Select job work locations" options={listOfWorkLocations} value={selectedJobWorkLocationsOptions} onChange={handleJobWorkLocationsChange} isDisabled={isReadOnly} closeMenuOnSelect={false}/>
                    </div>
                    
                    {/*<div className="form-group">
                        <Form.Check>
                            <Form.Check.Input type={'checkbox'} checked={isWorkFromHome} onChange={() => setIsWorkFromHome(!isWorkFromHome)}/>
                            <Form.Check.Label>
                                Flexible/work from home
                            </Form.Check.Label>
                        </Form.Check>
                    </div>*/}
                    <div className="form-group dropdown-margin">
                        <label for="workingHours">Working hours <label style={{ color: 'red' }}>*</label></label>
                        <Select isMulti={true} placeholder="Select job working hours" options={listOfWorkHours} value={selectedWorkingHoursOptions} onChange={handleWorkingHoursChange} isDisabled={isReadOnly} closeMenuOnSelect={false}/>
                    </div>
                    
                    <div className="form-group">
                        <label>
                            <label for="contact">Contact <label style={{ color: 'red' }}>*</label></label>
                            <BiHelpCircle data-tip="React-tooltip" data-for="contact-tooltip"/>
    
                            <ReactTooltip id="contact-tooltip" place="right" type="dark" effect="float">
                                <span>We advise that you appoint a key contact as this will help applicants to feel confident about applying and encourage them to effectively disclose at the start of your process.</span>
                            </ReactTooltip>
                        </label>
                        <input  className={"form-control"} disabled={isReadOnly} id="contact" placeholder="Contact" value={state.contact} onChange={handleChange} required/>
                    </div>
                </div>
            </div>
            <div className="sections form-margin">
                <div className="form-group">
                    <label for="aboutTheCompany">About the company</label>
                    <RichTextEditor id="aboutCompany" disabled={((!isReadOnly && isAdmin) ? false : true)} value={state.aboutCompany} onChange={handleACOnChange}/>
                </div>
                <div className="form-group dropdown-margin">
                    <label>
                        <label for="companySpecialize">Company specialism</label>
                        <BiHelpCircle data-tip="React-tooltip" data-for="companySpecialize-tooltip"/>
                        <ReactTooltip id="companySpecialize-tooltip" place="right" type="dark" effect="float">
                            <span>What does your company specialise in</span>
                        </ReactTooltip>
                    </label>
                    <RichTextEditor id="companySpecialize" disabled={((isAdmin) ?false : true)} value={state.companySpecialize} onChange={handleCSOnChange}/>
                </div>
                <div className="form-group dropdown-margin">
                    <label>
                        <label for="companyPractices">Company practices</label>
                        <BiHelpCircle data-tip="React-tooltip" data-for="companyPractices-tooltip"/>
                        <ReactTooltip id="companyPractices-tooltip" place="right" type="dark" effect="float">
                            <span>What practices do you currently employ for those prospective candidates on the autistic spectrum</span>
                        </ReactTooltip>
                    </label>
                    <RichTextEditor id="companyPractices" disabled={((isAdmin) ?false : true)} value={state.companyPractices} onChange={handleCPOnChange}/>
                </div>
                <div className="form-group dropdown-margin">
                    <label>
                        <label for="roleDescription">Role description and benifits<label style={{ color: 'red' }}>*</label></label>
                        <BiHelpCircle data-tip="React-tooltip" data-for="roleDescription-tooltip"/>
        
                        <ReactTooltip id="roleDescription-tooltip" place="right" type="dark" effect="float">
                            <span>Avoid ambiguous language & be clear about communication expectations, phone answering etc. Describe the workplace/culture: creative/lively, or process driven/measured? Use bullets for the main duties. </span>
                        </ReactTooltip>
                    </label>
                    <RichTextEditor id="roleDescription" disabled={isReadOnly} value={state.roleDescription} onChange={handleRoleOnChange}/>
                </div>
                <div className="form-group dropdown-margin">
                    <label>
                        <label for="howToApply">How To Apply<label style={{ color: 'red' }}>*</label></label>
                        <BiHelpCircle data-tip="React-tooltip" data-for="howToApply-tooltip"/>
        
                        <ReactTooltip id="howToApply-tooltip" place="right" type="dark" effect="float">
                            <span>We are waiting for this tooltip content</span>
                        </ReactTooltip>
                    </label>
                    <RichTextEditor id="howToApply" disabled={isReadOnly} value={state.howToApply} onChange={handleHowToApplyOnChange}/>
                </div>
                <div className="form-group dropdown-margin">
                    <label className="about-team-label">
                    <label className="about-team-label" for="aboutTeam">About the team <label style={{ color: 'red' }}>*</label></label>
                        <BiHelpCircle data-tip="React-tooltip" data-for="aboutTeam-tooltip"/>

                        <ReactTooltip id="aboutTeam-tooltip" place="right" type="dark" effect="float">
                            <span>Please briefly describe the team environment for this position as autistic people might find it helpful when considering applying.</span>
                        </ReactTooltip>
                    </label>
                    <RichTextEditor id="aboutTeam" disabled={isReadOnly} value={state.aboutTeam} onChange={handleTeamOnChange}/>
                </div>
                <div className="form-group dropdown-margin"></div>
                <div className="form-group">
                    <label for="listingLink">Link to full job description <label style={{ color: 'red' }}>*</label></label>
                    <input  className={"form-control"} disabled={isReadOnly} id="link" placeholder="Link to full job description" value={state.link} onChange={handleChange} required/>
                </div>
                <div className="form-group dropdown-margin"></div>
            </div>
    
            <div>
                {props.role === SUPER_ADMIN &&
                <>
                    {jobStatus === "PENDINGREVIEW" &&
                    <>
                        <Button type="submit" disabled={!formValid} title={"Approve"} onClick={() => handleSubmitClick("ACTIVE")}/>
                        <Button type="submit" title={"Reject"} onClick={() => handleSubmitClick("REJECTED")}/>
                    </>
                    }
                    {jobStatus === "ACTIVE" && <Button type="submit" disabled={!formValid} title={"Resubmit"} onClick={() => handleSubmitClick("PENDINGREVIEW")}/>}                   
                </>}
                {(props.role !== SUPER_ADMIN && !isInactive) &&
                <>
                    <Button type="submit" disabled={!formValid} title={"Resubmit"} onClick={() => handleSubmitClick("PENDINGREVIEW")}/>
                </>}
                <Button type="submit" title={"Delete"} onClick={handleDeleteClick}/>
                {!isReadOnly && <Button type="submit" title={"Cancel"} onClick={cancelChanges}/>}
            </div>
        </div>
    
        )
    }
}

function JobSubmissionSuccess({action, companyName, ...props}) {
    const handleDashboardClick = (e) => {
        props.history.push('/my-dashboard');
    }

    if (action === "PENDINGREVIEW") {
        return (
            <div className="general-form">
                <div className="submission-status">
                    <h1 className="title">Congratulations!</h1>
                    <br/>
                    <text className="account-state">You have successfully posted a new job listing!</text>
                    <br/>
                    <text className="account-state">An email has been sent to a NAS admin to review your submission.</text>
                </div>
                <br/>
                <br/>
                <div className="buttons">
                    <Button type="submit" disabled={false} title={"Back to dashboard"} onClick={handleDashboardClick}/>
                </div>
            </div>
        )
    } else if (action === "delete") {
        return (
            <div className="general-form">
                <div className="submission-status">
                    <h1 className="title">Congratulations!</h1>
                    <br/>
                    <text className="account-state">You have successfully deleted this job listing!</text>
                </div>
                <br/>
                <br/>
                <div className="buttons">
                    <Button type="submit" disabled={false} title={"Back to dashboard"} onClick={handleDashboardClick}/>
                </div>
            </div>
        )
    } else if (action === "REJECTED") {
        return (
            <div className="general-form">
                <div className="submission-status">
                    <h1 className="title">Too bad!</h1>
                    <br/>
                    <text className="account-state">This job listing has been rejected.</text>
                    <br/>
                    <text className="account-state">Please let a {companyName} user know the reason for this rejection.</text>
                </div>
                <br/>
                <br/>
                <div className="buttons">
                    <Button type="submit" disabled={false} title={"Back to dashboard"} onClick={handleDashboardClick}/>
                </div>
            </div>
        )
    } else {
        return (
            <div className="general-form">
                <div className="submission-status">
                    <h1 className="title">Congratulations!</h1>
                    <br/>
                    <text className="account-state">You have approved this job listing!</text>
                    <br/>
                    <text className="account-state">It is now live on the platform.</text>
                </div>
                <br/>
                <br/>
                <div className="buttons">
                    <Button type="submit" disabled={false} title={"Back to dashboard"} onClick={handleDashboardClick}/>
                </div>
            </div>
        )
    }
}

export default withRouter(Job);