import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { deleteUser, getUsers } from '../../services/UserService';
import '../ConsentingUsers/ConsentingUsers.css';
import './Employers.css';

function Employers(props) {

    const [employerUsers, setEmployerUsers] = useState([]);

    useEffect(() => {
        getUsers().then(response => {
            if (response.length > 0) {
                setEmployerUsers(response.filter(element => element.type === 'employer'))
            }
        })
    }, []);

    function handleDeleteUser(userId) {
        if (window.confirm("Are you sure you want to delete this user?")) {
            deleteUser(userId).then((response) => {
                window.location.reload();
            })
        }
    }

    return (
        <div className="align-left">
            <h1 className="title title-job title-candidate">Employers</h1>
            {employerUsers.length > 0 && 
            <>
                <h2 className="smaller-title">You have the following employer users signed up to your service:</h2>
                <div className="consenting-candidates">
                {employerUsers.map((user) =>
                        <div className="consenting-candidate employer-user-box">
                            <div className="user-bottom">
                                <div>
                                    {user.employer.name}
                                </div>
                                <a className="simple-text delete-link" onClick={() => handleDeleteUser(user.userId)}>
                                    Delete 
                                </a>
                            </div>
                            <div className="user-bottom">
                                <div>
                                    {user.firstName + " " + user.lastName}
                                </div>
                                <div>
                                    {user.companyRole}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </>}
            {employerUsers.length === 0 &&
            <h2 className="smaller-title">You do not have any employer users signed up to your service.</h2>}
        </div>
        )
}

export default withRouter(Employers);