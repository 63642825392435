import React, { useState, useEffect} from 'react';
import Button from '../../components/Button/Button.js';
import AlertDismissible from '../../components/AlertDismissible/AlertDismissible.js';
import {getContentPageById,updatePageContent} from '../../services/ContentPagesService';
import './ContentPages.css';
import { withRouter,useLocation } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { BiHelpCircle } from 'react-icons/bi';

import Dropdown from "react-bootstrap/Dropdown";
import { EMPTY_STRING } from "../../constants/formConstants";
import RichTextEditor from 'react-rte';


function ContentPageEdit(props) {
    /*const [ContentPageEdit, setContentPageEdit] = useState();*/ 
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [submitStatus, setSubmitStatus] = useState(0);
    const [action, setAction] = useState();
    const [isInactive, setIsInactive] = useState();
    const [formValid, setFormValid] = useState();
    
    const queryParams = useLocation().search;
    const pageid = new URLSearchParams(queryParams).get('pageid');

    const [state, setState] = useState({
        pageName: EMPTY_STRING,
        pageLink: EMPTY_STRING,
        longDescription: RichTextEditor.createEmptyValue(),
        status: EMPTY_STRING,
    })

    /*const [backupState, setBackupState] = useState({
        pageName: EMPTY_STRING,
        longDescription: RichTextEditor.createEmptyValue(),
        status: EMPTY_STRING,
    })*/

    useEffect(() => {
        if(pageid){
            getContentPageById(pageid).then((response) => {
                setState(prevState => ({
                    ...prevState,
                    pageName: response.pageName || "",
                    pageLink: response.pageLink || "",
                    longDescription: RichTextEditor.createValueFromString(response.longDescription, 'html') || "",
                    status: (response.status==="1")?"Active":"InActive" || ""
                }))
                /*setContentPageEdit(response);*/
                setIsInactive(0);
                setFormValid(false); 
            }).catch(function () {
                setSubmitStatus(-1);
            });
        }
      }, [pageid])

      
    const editPageContent = () => {
        setIsReadOnly(false);
        setFormValid(true);
        /*setBackupState({
            ...state,
            ContentPageEdit
        });*/ 
        /*setContentPageEdit();*/
    }
    
    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    const cancelChanges = () => {
        setIsReadOnly(true)
        /*setContentPageEdit();*/
        setFormValid(false);
        /*window.location.href='/#/content-pages';*/
    }

    
    const handleOnChange = (value) => {
        setState(prevState => ({
            ...prevState,
            longDescription:value
        }))
        if (props.OnChange) {
            // Send the changes up to the parent component as an HTML string.
            // This is here to demonstrate using `.toString()` but in a real app it
            // would be better to avoid generating a string on each change.
            props.OnChange(
                value.toString('html')
            );
        }
    };


    /*const formValid = Object.values(state).every(v => v.length);*/
    
    function handleSubmitClick() {
        const payload = {
            "id": pageid,
            "pageName": state.pageName,
            "pageLink":state.pageLink,
            "longDescription": state.longDescription.toString('html'),
            "status": (state.status==="Active")?"1":"0"
        }
            
        updatePageContent(payload).then(function (response) {
            setAction("UPDATEDCONTENTVIEW");
            setSubmitStatus(1);
        })
        .catch(function (error) {
            setSubmitStatus(-1);
        });
    }

    const handleSelect = (e, value) => {
        const { id, text } = value.target
        setState(prevState => ({
            ...prevState,
            [id]: text
        }))
    } 
    const backPageContentList = (e) => {
        props.history.push('/content-pages');
    }
    
    /*const handleDeleteClick = (e) => {
        e.preventDefault();
        if (window.confirm("Are you sure you want to delete this listing?")) {
            deleteListing(id).then(function (response) {
                setAction("delete");
                setSubmitStatus(1);
            })
            .catch(function (error) {
                setSubmitStatus(-1);
            });
        } else {
            window.location.reload();
        }
    }*/
    if (submitStatus === 1) {
        return (
            <div>
                <UpdateContentSuccess action={action} pageName={state.pageName} {...props}/>
            </div>
        );
    } else {
        return (
            <div className="content-pages-application-submission border-opacity-25">
                <div className="title-container">
                    <Button type="submit" title={"Back content pages"} onClick={backPageContentList}><i class="fa fa-arrow-left" aria-hidden="true"></i></Button>
                    <h1 className="title title-job">{state.pageName}</h1>
                    {!isInactive && <Button type="submit" disabled={false} title={"Edit"} onClick={editPageContent}/>}
                </div>

                {submitStatus === -1 && <AlertDismissible onClose={() => setSubmitStatus(0)} alertMessage={"Something went wrong when posting your listing. Please try again."}/>}
                <div className="form-margin">
                    <div className="form-group">
                        <label>
                            <label for="title">Page title <label style={{ color: 'red' }}>*</label></label>
                            <BiHelpCircle data-tip="React-tooltip" data-for="title-tooltip"/>
    
                            <ReactTooltip id="title-tooltip" place="right" type="dark" effect="float">
                                <span>Please update page title. if you want to edit</span>
                            </ReactTooltip>
                        </label>
                        <input  className={"form-control"} disabled={1} id="pageName" placeholder="Page Name" value={state.pageName} onChange={handleChange} required/>
                    </div>
                    <div className="form-group">
                        <label>
                            <label for="title">Page Link <label style={{ color: 'red' }}>*</label></label>
                            <BiHelpCircle data-tip="React-tooltip" data-for="title-tooltip"/>
    
                            <ReactTooltip id="title-tooltip" place="right" type="dark" effect="float">
                                <span>Please update page link if you want to edit</span>
                            </ReactTooltip>
                        </label>
                        <input  className={"form-control"} disabled={isReadOnly} id="pageLink" placeholder="Please enter page link" value={state.pageLink} onChange={handleChange} required/>
                    </div>
                    <div className="form-group dropdown-margin">
                        <label for="status">Status <label style={{ color: 'red' }}>*</label></label>
                        <Dropdown className="form-group employer-dropdown" onSelect={handleSelect}>
                            <Dropdown.Toggle className="bootstrap-dropdown" variant="light" id="dropdown-basic" disabled={isReadOnly}>
                                {state.status || "InActive"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item id="status">Active</Dropdown.Item>
                                <Dropdown.Item id="status">InActive</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="form-group">
                        <label>
                            <label for="longDescription">Long Description <label style={{ color: 'red' }}>*</label></label>
                            <BiHelpCircle data-tip="React-tooltip" data-for="longDescription-tooltip"/>
            
                            <ReactTooltip id="longDescription-tooltip" place="right" type="dark" effect="float">
                                <span>longDescription </span>
                            </ReactTooltip>
                        </label>
                        <RichTextEditor disabled={isReadOnly} id="longDescription" value={state.longDescription} onChange={handleOnChange}
                        />
                    </div>
                    <div>
                        <Button type="submit" disabled={!formValid} title={"Submit"} onClick={() => handleSubmitClick()}/>
                        {!isReadOnly && <Button type="submit" title={"Cancel"} onClick={cancelChanges}/>}
                    </div>
                </div>
            </div>
        )
    }
}

function UpdateContentSuccess({action, pageName, ...props}) {
    const handleContentPagesClick = (e) => {
        props.history.push('/content-pages');
    }

    if (action === "UPDATEDCONTENTVIEW") {
        return (
            <div className="general-form">
                <div className="submission-status">
                    <h1 className="title">{pageName} Success!</h1>
                    <br/>
                    <text className="account-state">You have successfully updated your content!</text>
                </div>
                <br/>
                <br/>
                <div className="buttons">
                    <Button type="submit" disabled={false} title={"Back to content pages"} onClick={handleContentPagesClick}/>
                </div>
            </div>
        )
    }
}

export default withRouter(ContentPageEdit);