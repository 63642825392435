function textEditorFieldCheck(inputFieldData) {
    const emptyCheckRegex = /(<([^>]+)>)/ig
    return (!!inputFieldData.replace(emptyCheckRegex, "").length===false)?false:true;
}
function textSelectedFieldCheck(inputFieldData) {
    if(Array.isArray(inputFieldData) && inputFieldData.length>0){
        return true;
    }else if(Object.keys(inputFieldData).length>0){
        return true;
    }else if(inputFieldData!=""){
        return true;
    }else{
        return false;
    }
}
function fieldErrorLengthCheck(inputFieldData) {
    if(Array.isArray(inputFieldData) && inputFieldData.length>0){
        return false;
    }else if(Object.keys(inputFieldData).length>0){
        return false;
    }
    return true;
}


export {
    textEditorFieldCheck,
    textSelectedFieldCheck,
    fieldErrorLengthCheck
};