import React, { useState } from 'react';
import Button from '../../components/Button/Button.js';
import { postResetPassword } from '../../services/LoginService';
import { EMPTY_STRING } from "../../constants/formConstants";

function Form(props) {
    const [state, setState] = useState({
        password: EMPTY_STRING,
        passwordConfirm: EMPTY_STRING,
    })

    const formFilled = Object.values(state).every(v => v.length);
    const passwordValid = state.password === state.passwordConfirm;
    const formValid = formFilled && passwordValid;

    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))        
    }

    const handleSubmitClick = (e) => {
        e.preventDefault();
        postResetPassword(props.token, state.password).then(function (response) {
            props.onFormSubmit(1);
        }).catch(function (error) {
            props.onFormSubmit(-1);
        });
    }

    return (
        <div className="field-form">
            <h1 className="title">Reset Password</h1>
            <form>
                <div className="form-group">
                    <label for="password">New Password</label>
                    <input className={"form-control"} type="password" id="password" placeholder="New Password" value={state.password} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label for="password">Confirm New Password</label>
                    <input className={"form-control"} type="password" id="passwordConfirm" placeholder="Confirm New Password" value={state.passwordConfirm} onChange={handleChange} />
                </div>
            </form>
            <div>
                <Button type={"submit"} disabled={!formValid} title={"Submit"} onClick={handleSubmitClick} />  
            </div>
        </div>
    )  
}

export default Form;