import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import {getAllPagesListings} from '../../services/ContentPagesService';
//import '../ConsentingUsers/ConsentingUsers.css';
import './ContentPages.css';

function ContentPages(props) {

    const [contentPages, setContentPages] = useState([]);

    useEffect(() => {
        getAllPagesListings().then(response => {
            setContentPages(response)
        })
    }, []);

    function handleEditContentPage(pageId) {
        window.location.href='/#/content-pages-edit?pageid='+pageId;
        /*if (window.confirm("Are you sure you want to edit this page?")) {
            deleteUser(userId).then((response) => {
                window.location.reload();
            })
        }*/
    }
    function truncatePageListString(pageContent) {
        return pageContent.length > 200 ? pageContent.substring(0,200) + "..." : pageContent;
    }
    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });


    return (
        <div className= "d-flex flex-column page">
            {contentPages.length > 0 && 
            <>
                <h2 className="headingTitle">Content pages</h2>
            <div className= "table-div">      
                <table className="table table-striped table-hover">
                    <thead className= "table-dark">
                    <tr >
                        <th scope="col">Section</th>
                        <th scope="col">Page title</th>
                        <th scope="col">External Link</th>
                        <th scope="col">Description</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {contentPages.map((pageContent)=>
                        <tr >
                            <th scope="row">{pageContent.id}</th>
                            <td>{pageContent.pageName}</td>
                            <td>{pageContent.pageLink}</td>
                            <td class="pageContentColumn">{renderHTML(truncatePageListString(pageContent.longDescription))}</td>
                            <td>{pageContent.status==='1'?"Enable":"Disable"}</td>
                            <td>
                                <button className="simple-text delete-link btn btn-primary" onClick={() => handleEditContentPage(pageContent.id)}>Edit</button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div> 
            </>}
            {contentPages.length === 0 &&
            <h2 className="smaller-title">You do not have any content pages.</h2>}
        </div>
    )
}

export default withRouter(ContentPages);



