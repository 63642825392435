import axios from "axios";
import FileDownload from "js-file-download";
import {
  API_BASE_URL,
  API_URL_EXTENSION,
  ACCESS_TOKEN_NAME,
} from "../constants/apiConstants";

function postJobSubmission(payload) {
  const url = API_BASE_URL + API_URL_EXTENSION + "/joblisting/create";
  const body = {
    "employerId": payload.employerId,
    "companyName": payload.companyName,
    "deadline": payload.applicationDeadline,
    "title": payload.title,
    "workingHours": payload.workingHours,
    "contractType": payload.contractType,
    "salary": payload.salary,
    "city": payload.city,
    "flexible": payload.isWorkFromHome,
    "companyInfo": payload.aboutCompany,
    "teamInfo": payload.aboutTeam,
    "contactInfo": payload.contact,
    "description": payload.roleDescription,
    "rolesAvailable": payload.rolesAvailable,
    "qualification": payload.careerLevel,
    "applicationLink": payload.link,
    "jobSectors": payload.jobSectors,
    "jobRegions": payload.jobRegions,
    "workingLocations": payload.workingLocations,
    "companySpecialize": payload.companySpecialize,
    "companyPractices": payload.companyPractices,
    "howToApply":payload.howToApply
  };
  console.log(body);

  return axios.post(url, body, {'headers': {'Authorization':localStorage.getItem(ACCESS_TOKEN_NAME)}})
      .then(function(response) {
          return response.data
      })
}

function getAllListings() {
  const url = 
    API_BASE_URL + 
    API_URL_EXTENSION + 
    "/joblisting/allJoblistings";
  
  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      console.log(response.data);
      return response.data;
    });
}

function getAllLists() {
  const url =API_BASE_URL+API_URL_EXTENSION+"/joblisting/get-all-job-lists";
  
  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return response.data;
    });
}

function getAllListingsByEmployerId(employerId) {
  const url = 
    API_BASE_URL + 
    API_URL_EXTENSION + 
    "/joblisting/byEmployerIdAndStatus?employerId=" + employerId;
  
  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return response.data;
    });
}

async function getJob(joblistingId) {
  const url =
    API_BASE_URL +
    API_URL_EXTENSION +
    "/joblisting/joblistingById?joblistingId=" +
    joblistingId;

  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return {};
    });
}

function getListingCountByStatus(employerId) {
  const url =
    API_BASE_URL +
    API_URL_EXTENSION +
    "/joblisting/countByStatus?employerId=" +
    employerId;

  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    });
}

function deleteListing(listingId) {
  const url = API_BASE_URL + API_URL_EXTENSION + "/joblisting/delete?listingId=" + listingId;
  return axios
    .delete(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
      data: {}
    })
    .then(function (response) {
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    })
    .catch(function (error) {
      return false;
    });
}

function downloadJoblistingsToCsv(employerId) {
  let url = 
    API_BASE_URL + 
    API_URL_EXTENSION + 
    "/joblisting/downloadAsCsv";
  
  if (employerId) {
    url += "?employerId=" + employerId;
  }

  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
      responseType: "blob",
    })
    .then(function (response) {
      FileDownload(response.data, "listings-report.csv");
    })
    .catch(function (error) {
      return [];
    });
}

function putJobListing(payload) {
  const url = API_BASE_URL + API_URL_EXTENSION + `/joblisting/${payload.id}`;

  return axios.put(
    url, 
    payload, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function(response) {
      return response.data
    });
}

async function getAllPreferences(requestType) {
  const url =getApiActiveRecordUrl(requestType);
  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      if(Object.keys(response.data).length>0){
        let preferences=response.data;
        let preferencesOptions = [];
        preferencesOptions=generateAllKeyPairObjects(preferences);
        return preferencesOptions;
      }
      return response.data;
    });
}

/** Get Types based url */
function getApiActiveRecordUrl(requestType){
  const url =API_BASE_URL + API_URL_EXTENSION;
  switch(requestType) {
    case 'preferences':
      return url+"/resource/all-preferences";
    case 'jobroles':
      return url+"/job-roles/get-all-active-job-roles";
    case 'jobcontacttypes':
      return url+"/job-contract-types/get-all-active-job-contract-types";
    case 'jobsectors':
      return url+"/job-sectors/get-all-active-job-sectors";
    default:
      return url;
  }
}
async function getAllActiveSectorJobRoles(parent) {
  const url =API_BASE_URL + API_URL_EXTENSION+"/job-roles/get-active-job-roles-by-sector?parent="+parent;
  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      if(Object.keys(response.data).length>0){
        let preferences=response.data;
        let preferencesOptions = [];
        preferencesOptions=generateKeyPairObject(preferences);
        return preferencesOptions;
      }
      return response.data;
    });
}
/** gernerate key object for all options */
function generateAllKeyPairObjects(dataPayload){
  let optionsData=[];
  if(Object.keys(dataPayload).length>0){
    Object.keys(dataPayload).forEach(key => {
      let currentKeyData=dataPayload[key];
      optionsData[key]=[];
      for(let i = 0; i < currentKeyData.length; i++) {
        optionsData[key].push({
          value:currentKeyData[i].id,
          key: currentKeyData[i].id,
          label:currentKeyData[i].title,
        });
      }    
    });
  }
  return optionsData;
}
/** convert option value by comma separte */
function optionsToCommaString(optionPayload){
  let optiondata="";
  if(optionPayload.length>0){
    for(let i = 0; i < optionPayload.length; i++) {
      optiondata+=optionPayload[i].value;
      let counter=i+1;
      if(optionPayload.length>counter){
        optiondata+=",";
      }
    }
    optiondata.trimEnd(',');
  }
  return optiondata;
}
/** convert comma separte value to options  */
function commaStringToOptions(commaStringPayload,optionsPayload){
  var selectedOptionsData=[];
  var selectedPayload = commaStringPayload.split(',');
  optionsPayload.forEach(optionData => {
    const selectedCheck=selectedPayload.some(selectedValue => {
      if(parseInt(selectedValue) === optionData.value) {
        return true;
      } 
      return false;
    });
    if(selectedCheck) {
      selectedOptionsData.push({
        value:optionData.value,
        key: optionData.key,
        label:optionData.label,
      });
    } 
  });
  return selectedOptionsData;
}
/** convert comma separte value to option  */
function commaStringToOption(commaStringPayload,optionsPayload){
  var selectedOptionsData=[];
  var selectedPayload = commaStringPayload.split(',');
  optionsPayload.forEach(optionData => {
    const selectedCheck=selectedPayload.some(selectedValue => {
      if(parseInt(selectedValue) === optionData.value) {
        return true;
      } 
      return false;
    });
    if(selectedCheck) {
      selectedOptionsData=optionData;
    } 
  });
  return selectedOptionsData;
}
/** gernerate key object for options */
function generateKeyPairObject(dataPayload){
  let optionsData=[];
  if(dataPayload.length>0){
    for(let i = 0; i < dataPayload.length; i++) {
      optionsData.push({
        value:dataPayload[i].id,
        key: dataPayload[i].id,
        label:dataPayload[i].title,
      });
    }
  }
  return optionsData;
}


export {
  postJobSubmission,
  getAllListings,
  getAllLists,
  getAllListingsByEmployerId,
  getJob,
  putJobListing,
  getListingCountByStatus,
  deleteListing,
  downloadJoblistingsToCsv,
  optionsToCommaString,
  getAllPreferences,
  commaStringToOptions,
  commaStringToOption,
  getAllActiveSectorJobRoles,
};