import React, { useState } from 'react';
import Button from '../../components/Button/Button.js';
import AlertDismissible from '../../components/AlertDismissible/AlertDismissible.js';
import {postAddAdmin} from '../../services/UserService';
import { EMPTY_STRING } from "../../constants/formConstants";

const emailRegex = /[a-z]+(\.|_)?([a-z0-9]+(\.|_)?)+?[a-z0-9]+@[a-z0-9]+_?[a-z0-9]+\.[a-z]+/;

function Form(props) {

    const [state, setState] = useState({
        companyRole: EMPTY_STRING,
        firstName: EMPTY_STRING,
        lastName: EMPTY_STRING,
        email: EMPTY_STRING,
    })
    const [newUserStatus, setNewUserStatus] = useState(0);
    const formFilled = Object.values(state).every(v => v.length);
    const emailValid = emailRegex.test(state.email);
    const formValid = formFilled && emailValid;

    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))   
    }

    const handleSubmitClick = (e) => {
        e.preventDefault();
        postAddAdmin(state.companyRole, state.firstName, state.lastName, state.email, state.password)
            .then(function (response) {
                console.log(response.success);
                if(response.success==="FALSE"){
                    setNewUserStatus(1);
                    props.onFormSubmit(-1);
                }else{
                    props.onFormSubmit(1);
                }
        }).catch(function (error) {
            props.onFormSubmit(-1);
        });
    }
    
    const redirectToDashboard = (e) => {
        e.preventDefault()
        props.history.push('/my-dashboard');
    }
    if (newUserStatus === 1) {
        return (
            <div className="submission-status">
                <h1 className="title">Sorry Something went wrong when creating this account</h1>
                <br/>
                <div className="account-state">Maybe this email address already exists!</div>
                <div>
                    <br/>
                    <Button type={"submit"} title={"Dashboard"} onClick={redirectToDashboard} />
                </div>
            </div>
        );
    } else {
        return (
            <div className="field-form">
                <h1 className="title">Add admin</h1>
                {props.status === -1 && <AlertDismissible onClose={() => props.onFormSubmit(0)} alertMessage={"Something went wrong when creating this account. Please try again."}/>}
                <form>
                    <div className="form-group">
                        <label for="companyRole">Company role</label>
                        <input className={"form-control"} id="companyRole" placeholder="Company role" value={state.companyRole} onChange={handleChange}/>
                    </div>
                    <div className="form-group">
                        <label for="firstName">First name</label>
                        <input className={"form-control"} id="firstName" placeholder="First name" value={state.firstName} onChange={handleChange}/>
                    </div>
                    <div className="form-group">
                        <label for="lastName">Last name</label>
                        <input className={"form-control"} id="lastName" placeholder="Last name" value={state.lastName} onChange={handleChange}/>
                    </div>
                    <div className="form-group">
                        <label for="email">Email address</label>
                        <input className={"form-control" + ((formFilled && !emailValid) ? " is-invalid" : "")} id="email" placeholder="Email address" value={state.email} onChange={handleChange}/>
                    </div>
                </form>
                <div>
                    <Button type={"submit"} disabled={!formValid} title={"Create account"} onClick={handleSubmitClick} />
                </div>
            </div>
        )
    }
}  

export default Form;