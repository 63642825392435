import React, { useState, useEffect } from 'react';
import Button from '../../components/Button/Button.js';
import AlertDismissible from '../../components/AlertDismissible/AlertDismissible.js';
import Form from "react-bootstrap/Form";
import { withRouter } from "react-router-dom";
import './User.css';
import { EMPTY_STRING } from "../../constants/formConstants";
import {useLocation} from "react-router-dom";
import { getNewUser, putNewUserApproval } from "../../services/UserService.jsx";
import { USER_EXISTS } from '../../constants/alertConstants.js';

function User(props) {
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [submitStatus, setSubmitStatus] = useState(0);
    const [isInvoiced, setIsInvoiced] = useState(false);
    const queryParams = useLocation().search;
    const id = new URLSearchParams(queryParams).get('id');
    const [password, setPassword] = useState(EMPTY_STRING);
    const [requestOn, setRequestOn] = useState(EMPTY_STRING);

    const [state, setState] = useState({
        companyName: EMPTY_STRING,
        contactFirstName: EMPTY_STRING,
        contactLastName: EMPTY_STRING,
        contactEmail: EMPTY_STRING,
        companyWebsite: EMPTY_STRING,
        companyRole: EMPTY_STRING,
        aboutCompany: EMPTY_STRING
    });

    const [backupState, setBackupState] = useState();

    useEffect(() => {
        getNewUser(id).then((response) => {
            for (const [key, value] of Object.entries(response)) {
                setState(prevState => ({
                    ...prevState,
                    companyName: response.companyName || "",
                    contactFirstName: response.contactFirstName || "",
                    contactLastName: response.contactLastName || "",
                    contactEmail: response.contactEmail || "",
                    companyWebsite: response.companyWebsite || "",
                    companyRole: response.companyRole || "",
                    aboutCompany: response.aboutCompany || ""
                }));
            }
            setPassword(response.password || "");
            setRequestOn(response.requestOn || "");
        }).catch(function () {
            setSubmitStatus(-1);
        });

    }, [id]);

    const formValid = Object.values(state).every(v => v.length);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    const editJob = () => {
        setIsReadOnly(false);
        setBackupState(state);
    }

    const approveApplication = () => {
        putNewUserApproval(
            id, 
            state.companyName,
            props.companyId,
            state.companyWebsite,
            state.aboutCompany,
            requestOn,
            "CLOSED",
            state.companyRole,
            state.contactFirstName,
            state.contactLastName,
            state.contactEmail,
            password
        ).then(function (response) {
            if (response.details) {
                response.details[0] === USER_EXISTS ? setSubmitStatus(-2) : setSubmitStatus(1);
            } else {
                setSubmitStatus(1);
            }
        }).catch(function (error) {
            setSubmitStatus(-1);
        });
    }

    const rejectApplication = () => {
        putNewUserApproval(
            id, 
            state.companyName,
            props.companyId,
            state.companyWebsite,
            state.aboutCompany,
            requestOn,
            "REJECT",
            state.companyRole,
            state.contactFirstName,
            state.contactLastName,
            state.contactEmail,
            password
        ).then(function (response) {
            setSubmitStatus(2);
        }).catch(function (error) {
            setSubmitStatus(-1);
        });
    }

    const cancelChanges = () => {
        setIsReadOnly(true)
        setState(backupState);
    }

    if (submitStatus === 1) {
        return (
            <div>
                <NewUserApprove companyName={state.companyName} firstName={state.contactFirstName} lastName={state.contactLastName} {...props}/>
            </div>
        );
    } else if (submitStatus === 2) {
        return (
            <div>
                <NewUserReject companyName={state.companyName} firstName={state.contactFirstName} lastName={state.contactLastName} email={state.contactEmail} {...props}/>
            </div>
        );
    } else {
        return (
        <div className="user-request-submission border-opacity-25">
            <div className="title-container">
                <h1 className="title title-job">New User Form</h1>
                <Button type="submit" disabled={false} title={"Edit"} onClick={editJob}/>
            </div>
    
            {submitStatus === -1 && <AlertDismissible onClose={() => setSubmitStatus(0)} alertMessage={"Something went wrong with this request. Please try again."}/>}
            {submitStatus === -2 && <AlertDismissible onClose={() => setSubmitStatus(0)} alertMessage={"This user already exists and cannot be added."}/>}
            <div className="user-section form-margin">
                <div className="subsections">
                    <div className="form-group">
                        <label for="companyName">Company name</label>
                        <input  className={"form-control"} disabled={isReadOnly} id="companyName" placeholder="Company Name" value={state.companyName} onChange={handleChange} required/>
                    </div>
                    <div className="form-group">
                        <label for="firstName">First name</label>
                        <input className={"form-control"} disabled={isReadOnly} id="contactFirstName" placeholder="First Name" value={state.contactFirstName} onChange={handleChange} required/>
                    </div>
                    <div className="form-group">
                        <label for="email">Email address</label>
                        <input className={"form-control"} disabled={isReadOnly} id="contactEmail" placeholder="Email Address" value={state.contactEmail} onChange={handleChange} required/>
                    </div>
                </div>
    
                <div className="subsections">
                    <div className="form-group">
                        <label for="companyRole">Company role</label>
                        <input className={"form-control"} disabled={isReadOnly} id="companyRole" placeholder="Company role" value={state.companyRole} onChange={handleChange} required/>
                    </div>
                    <div className="form-group">
                        <label for="lastName">Last name</label>
                        <input className={"form-control"} disabled={isReadOnly} id="contactLastName" placeholder="Last name" value={state.contactLastName} onChange={handleChange} required/>
                    </div>
                    <div className="form-group">
                        <label for="website">Website</label>
                        <input className={"form-control"} disabled={isReadOnly} id="companyWebsite" placeholder="Website" value={state.companyWebsite} onChange={handleChange} required/>
                    </div>
                    <div className="form-group my-checkbox">
                        <Form.Check>
                            <Form.Check.Input type={'checkbox'} checked={isInvoiced === true} onChange={() => setIsInvoiced(!isInvoiced)}/>
                            <Form.Check.Label>
                                Invoiced
                            </Form.Check.Label>
                        </Form.Check>
                    </div>
                </div>
            </div>
            <div className="form-group form-margin">
                <label for="aboutTheCompany">About the company</label>
                <textarea cols="40" rows="3" className={"form-control"} disabled={isReadOnly} id="aboutCompany" placeholder="About the company" value={state.aboutCompany} onChange={handleChange} required/>
            </div>
            <div>
                <Button type="submit" disabled={!formValid || !isInvoiced} title={"Approve"} onClick={approveApplication}/>
                <Button type="submit" disabled={false} title={"Reject"} onClick={rejectApplication}/>
                {!isReadOnly && <Button type="submit" disabled={false} title={"Cancel"} onClick={cancelChanges}/>}
            </div>
        </div>
    
        )
    }
}

function NewUserApprove(props) {

    const handleDashboardClick = (e) => {
        props.history.push('/my-dashboard');
    }

    return (
        <div className="general-form">
            <div className="submission-status">
                <h1 className="title">Congratulations!</h1>
                <br/>
                <text className="account-state">The new employer request for {props.companyName} has been approved.</text>
                <br/>
                <text className="account-state">An account has been created for user {props.firstName + " " + props.lastName}.</text>
            </div>
            <br/>
            <br/>
            <div>
                <Button type="submit" disabled={false} title={"Back to dashboard"} onClick={handleDashboardClick}/>
            </div>
        </div>
    )
}

function NewUserReject(props) {

    const handleDashboardClick = (e) => {
        props.history.push('/my-dashboard');
    }

    return (
        <div className="general-form">
            <div className="submission-status">
                <h1 className="title">Too bad!</h1>
                <br/>
                <text className="account-state">The new employer request for {props.companyName} has been rejected</text>
                <br/>
                <text className="account-state">Please get in touch with user {props.firstName + " " + props.lastName} at this email address: {props.email}.</text>
            </div>
            <br/>
            <br/>
            <div>
                <Button type="submit" disabled={false} title={"Back to dashboard"} onClick={handleDashboardClick}/>
            </div>
        </div>
    )
}

export default withRouter(User);