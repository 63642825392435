import axios from "axios";
/*import FileDownload from "js-file-download";*/
import {
  API_BASE_URL,
  API_URL_EXTENSION,
  ACCESS_TOKEN_NAME,
} from "../constants/apiConstants";

/*function postJobSubmission(payload) {
  const url = API_BASE_URL + API_URL_EXTENSION + "/joblisting/create";
  const body = {
    "employerId": payload.employerId,
    "companyName": payload.companyName,
    "deadline": payload.applicationDeadline,
    "title": payload.title,
    "workingHours": payload.workingHours,
    "contractType": payload.contractType,
    "salary": payload.salary,
    "city": payload.city,
    "flexible": payload.isWorkFromHome,
    "companyInfo": payload.aboutCompany,
    "teamInfo": payload.aboutTeam,
    "contactInfo": payload.contact,
    "description": payload.roleDescription,
    "rolesAvailable": payload.rolesAvailable,
    "qualification": payload.careerLevel,
    "applicationLink": payload.link
  };

  return axios.post(url, body, {'headers': {'Authorization':localStorage.getItem(ACCESS_TOKEN_NAME)}})
      .then(function(response) {
          return response.data
      })
}*/

function getAllPagesListings() {
  const url = 
    API_BASE_URL + 
    API_URL_EXTENSION + 
    "/contentPages/getAllContentPages";
  
  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return response.data;
    });
}

function getContentPageById(pageId) {
  const url =
    API_BASE_URL +
    API_URL_EXTENSION +
    "/contentPages/getContentPageById?ContentPageId="+pageId;

  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return {};
    });
}

function updatePageContent(payload) {
  const url = API_BASE_URL + API_URL_EXTENSION + `/contentPages/updatePageContent/${payload.id}`;

  return axios.put(
    url, 
    payload, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function(response) {
      return response.data
    });
}




/*function getAllListingsByEmployerId(employerId) {
  const url = 
    API_BASE_URL + 
    API_URL_EXTENSION + 
    "/joblisting/byEmployerIdAndStatus?employerId=" + employerId;
  
  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return response.data;
    });
}*/

/*function getListingCountByStatus(employerId) {
  const url =
    API_BASE_URL +
    API_URL_EXTENSION +
    "/joblisting/countByStatus?employerId=" +
    employerId;

  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    });
}*/

/*function deleteListing(listingId) {
  const url = API_BASE_URL + API_URL_EXTENSION + "/joblisting/delete?listingId=" + listingId;
  return axios
    .delete(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
      data: {}
    })
    .then(function (response) {
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    })
    .catch(function (error) {
      return false;
    });
}*/

/*function downloadJoblistingsToCsv(employerId) {
  let url = 
    API_BASE_URL + 
    API_URL_EXTENSION + 
    "/joblisting/downloadAsCsv";
  
  if (employerId) {
    url += "?employerId=" + employerId;
  }

  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
      responseType: "blob",
    })
    .then(function (response) {
      FileDownload(response.data, "listings-report.csv");
    })
    .catch(function (error) {
      return [];
    });
}*/


export {
  /*postJobSubmission,*/
  getAllPagesListings,
  getContentPageById,
  updatePageContent,
  /*getAllListingsByEmployerId,
  getJob,
  getListingCountByStatus,
  deleteListing,
  downloadJoblistingsToCsv,*/
};