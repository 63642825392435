import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import Button from '../../components/Button/Button.js';
import { getConsentingCandidates, downloadCandidatesToCsv } from '../../services/UserService.jsx';
import './ConsentingUsers.css';

function ConsentingUsers(props) {

    const [newUsers, setNewUsers] = useState([]);

    useEffect(() => {
        getConsentingCandidates().then((response) => setNewUsers(response));
    }, []);

    const handleDownloadReport = (e) => {
        downloadCandidatesToCsv();
    }

    return (
        <div className="align-left">
            <span>
                <h1 className="title title-job title-candidate">Jobseekers</h1>
                <span className="smaller-title download-button-text">
                    Download as: 
                    <Button type="submit" disabled={newUsers.length === 0} title={"a spreadsheet"} onClick={handleDownloadReport}/>
                </span>
            </span>
            {newUsers.length === 0 &&
            <h2 className="smaller-title">Currently, no candidate has consented to being contacted.</h2>}
            {newUsers.length > 0 &&
            <>
                <h2 className="smaller-title">The following candidates have consented to being contacted further via email:</h2>
                <div className="consenting-candidates">
                    {newUsers.map((user) =>
                        <div className="consenting-candidate">
                            <div>
                                {user.firstName + " " + user.lastName}
                            </div>
                            <div>
                                <div>
                                    {user.email}
                                </div> 
                            </div>
                        </div>
                    )}
                </div>
            </>}
        </div>
        )
}

export default withRouter(ConsentingUsers);