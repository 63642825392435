import React from 'react';
import Button from '../../components/Button/Button.js';

function SubmitStatusAdmin(props) {

    const redirectToLogin = (e) => {
        e.preventDefault()
        props.history.push('/login');
    }

    return (
        <div className="submission-status">
            <h1 className="title">Your password has been reset.</h1>
            <br/>
            <div className="account-state">Please login using your new password.</div>
            <br/>
            <div>
                <br/>
                <Button type={"submit"} title={"Login"} onClick={redirectToLogin} />
            </div>
        </div>
    )
}

export default SubmitStatusAdmin;