import React from 'react';

function SubmitStatusCandidate() {
    return (
        <div className="submission-status">
            <h1 className="title">Your password has been reset.</h1>
            <br/>
            <div className="account-state">Please login to the app using your new password.</div>
            <br/>
        </div>
    )
}

export default SubmitStatusCandidate;