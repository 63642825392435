import React, { useState } from 'react';
import Button from '../../components/Button/Button.js';
import AlertDismissible from '../../components/AlertDismissible/AlertDismissible.js';
import {postForgotPassword} from '../../services/LoginService';
import { EMPTY_STRING } from "../../constants/formConstants";

const emailRegex = /[a-z]+(\.|_)?([a-z0-9]+(\.|_)?)+?[a-z0-9]+@[a-z0-9]+_?[a-z0-9]+\.[a-z]+/;

function Form(props) {
    const [state, setState] = useState({
        email: EMPTY_STRING,
    })

    const formFilled = Object.values(state).every(v => v.length);
    const emailValid = emailRegex.test(state.email);
    const formValid = formFilled && emailValid;

    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))        
    }

    const handleSubmitClick = (e) => {
        e.preventDefault();
        postForgotPassword(state.email).then(function (response) {
            props.onFormSubmit(1);
        }).catch(function (error) {
            props.onFormSubmit(-1);
        });
    }

    const redirectToRegister = (e) => {
        e.preventDefault()
        props.history.push('/sign-up');
    }

    return (
        <div className="field-form">
            <h1 className="title">Forgot Password</h1>
            {props.status === -1 && <AlertDismissible onClose={() => props.onFormSubmit(0)} alertMessage={"Something went wrong when resetting your password. Please try again."}/>}
            <form>
                <div className="form-group text-left">
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <input type="email"
                        className={"form-control" + ((formFilled && !emailValid) ? " is-invalid" : "")}
                        id="email"
                        aria-describedby="emailHelp"
                        placeholder="Enter email"
                        value={state.email}
                        onChange={handleChange}
                    />
                </div>
            </form>
            <div>
                <Button type={"submit"} disabled={!formValid} title={"Submit"} onClick={handleSubmitClick} />  
            </div>
            <div className="simple-text">
                Don't have an account? <a href="/sign-up" className="loginText link" onClick={redirectToRegister}>Sign up now</a>
            </div>
        </div>
    )  
}

export default Form;