import React, { useState } from 'react';
import Button from '../../components/Button/Button.js';
import AlertDismissible from '../../components/AlertDismissible/AlertDismissible.js';
import {createFaqCategory} from '../../services/FaqsService';
import './Faqs.css';
import { withRouter } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { BiHelpCircle } from 'react-icons/bi';
import Dropdown from "react-bootstrap/Dropdown";
import { EMPTY_STRING } from "../../constants/formConstants";

function CreateFaqCategory(props) {

    const [submitStatus, setSubmitStatus] = useState(0);
    const [state, setState] = useState({
        name: EMPTY_STRING,
        position: EMPTY_STRING,
        status: EMPTY_STRING,
    });
    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))        
    }
    
    const handleSelect = (e, value) => {
        const { id, text } = value.target
        setState(prevState => ({
            ...prevState,
            [id]: text
        }))
    }
    var checkFormValid=true;
    Object.entries(state).forEach(entry => {
        const [key, value] = entry;
        var array = ["status"];
        if(!array.includes(key) && (value==='')){
            checkFormValid=false;
        }
    });
    
    const cancelChanges = (e) => {
        props.history.push('/faq-categories');
    }
    const formValid=(checkFormValid===true)?true:checkFormValid;
    const handleSubmitClick = (e) => {
        e.preventDefault();
        const payload = {
            "name": state.name,
            "position": state.position,
            "status": (state.status==="Active")?"1":"0"
        }
        createFaqCategory(payload).then(function (response) {
            setSubmitStatus(1);
        }).catch(function (error) {
            setSubmitStatus(2);
        });
    }
    if (submitStatus === 1) {
        return (
            <div>
                <SubmitStatus {...props}/>
            </div>
        );
    } else {
        return (
            <div className="general-form create-question-form border-opacity-25">
                <div className="field-form">
                    <h1 className="title">Add New Category</h1>
                    {submitStatus === 2 && <AlertDismissible onClose={() => props.onFormSubmit(0)} alertMessage={"Something went wrong when adding this question. Please try again."}/>}
                    <div className="form-margin">
                        <div className="form-group">
                            <label>
                                <label for="name">Name <label style={{ color: 'red' }}>*</label></label>
                                <BiHelpCircle data-tip="React-tooltip" data-for="name-tooltip"/>
        
                                <ReactTooltip id="name-tooltip" place="right" type="dark" effect="float">
                                    <span>Please add new faq category.</span>
                                </ReactTooltip>
                            </label>
                            <input  className={"form-control"} id="name" placeholder="Category name" value={state.name} onChange={handleChange}  required/>
                        </div>
                        <div className="form-group">
                            <label>
                                <label for="position">Position <label style={{ color: 'red' }}>*</label></label>
                                <BiHelpCircle data-tip="React-tooltip" data-for="position-tooltip"/>
        
                                <ReactTooltip id="position-tooltip" place="right" type="dark" effect="float">
                                    <span>Please add new faq position.</span>
                                </ReactTooltip>
                            </label>
                            <input  className={"form-control"} id="position" placeholder="Category position" value={state.position} onChange={handleChange}  required/>
                        </div>
                        <div className="form-group dropdown-margin">
                            <label for="status">Status <label style={{ color: 'red' }}>*</label></label>
                            <Dropdown className="form-group employer-dropdown" onSelect={handleSelect}>
                                <Dropdown.Toggle className="bootstrap-dropdown" variant="light" id="dropdown-basic" >
                                    {state.status || "Active"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item id="status">Active</Dropdown.Item>
                                    <Dropdown.Item id="status">InActive</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div>
                            <Button type="submit" disabled={!formValid} title={"Submit"} onClick={handleSubmitClick}/>
                            <Button type="submit" title={"Cancel"} onClick={cancelChanges}/>
                        </div>
                    </div>                
                </div>
            </div>
        )  
    }
}

function SubmitStatus(props) {

    const redirectToFaqCategories = (e) => {
        e.preventDefault()
        props.history.push('/faq-categories');
    }

    return (
        <div className="submission-status">
            <h1 className="title">Congratulations!</h1>
            <br/>
            <div className="account-state">You have successfully added a new category!</div>
            <div>
                <br/>
                <Button type={"submit"} title={"Back"} onClick={redirectToFaqCategories} />
            </div>
        </div>
    )
}

export default withRouter(CreateFaqCategory);