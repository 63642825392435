import React, { useState, useEffect} from 'react';
import Button from '../../components/Button/Button.js';
import AlertDismissible from '../../components/AlertDismissible/AlertDismissible.js';
import {getQuestionById,updateQuestion,getAllActiveCategories} from '../../services/FaqsService';
import './Faqs.css';
import { withRouter,useLocation } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { BiHelpCircle } from 'react-icons/bi';

import Dropdown from "react-bootstrap/Dropdown";
import { EMPTY_STRING } from "../../constants/formConstants";
import RichTextEditor from 'react-rte';
import Select from 'react-select';


function UpdateQuestion(props) {
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [submitStatus, setSubmitStatus] = useState(0);
    const [action, setAction] = useState();
    const [isInactive, setIsInactive] = useState();
    const [formValid, setFormValid] = useState();
    const [selectedFaqCategoryOptions,setSelectedFaqCategoryOptions]= useState(""); 
    const [listOfFaqCategories, setListOfFaqCategories] = useState([]);
    const [isCategory, setIsCategory] = useState(false);
    const [categoryName, setCategoryName] = useState([]);
    
    const queryParams = useLocation().search;
    const questionId = new URLSearchParams(queryParams).get('questionId');
    const faqCategoryId = new URLSearchParams(queryParams).get('faqCategoryId');

    const [state, setState] = useState({
        question: EMPTY_STRING,
        categoryId: EMPTY_STRING,
        answer: RichTextEditor.createEmptyValue(),
        status: EMPTY_STRING,
        position:EMPTY_STRING
    })
    const getAllExistingData = (faqCategoryId,questionId) => {
        Promise.all([getAllActiveCategories(), getQuestionById(questionId)]).then(results => {
            if((results.length > 0) && (results.length===2)){
                const categories=results[0];
                if (Object.keys(categories).length === 0) {
                    setSubmitStatus(-1);
                } else {
                    setListOfFaqCategories(categories);
                    setIsReadOnly(false);
                }
                const quetsionInfo=results[1];
                console.log(quetsionInfo);
                if(Object.keys(quetsionInfo).length>0){
                    setState(prevState => ({
                        ...prevState,
                        question: quetsionInfo.question || "",
                        answer: RichTextEditor.createValueFromString(quetsionInfo.answer, 'html') || "",
                        status: (quetsionInfo.status===1)?"Active":"InActive" || "",
                        position: quetsionInfo.position || ""
                    }))
                    setIsInactive(0);
                    setFormValid(false);
                    if((faqCategoryId!==null) && (Object.keys(categories).length>0)){
                        for (var key in categories) {
                            if(categories[key].value===quetsionInfo.categoryId){
                                setSelectedFaqCategoryOptions(categories[key]);
                                setCategoryName(categories[key].label+":");
                                setIsCategory(true);
                                break;
                            } 
                        }
                    }
                }
            }
        });
    }
    useEffect(() => {
        if(questionId){
            getAllExistingData(faqCategoryId,questionId);
            /*getAllActiveCategories().then((response) => {
                if (response.length === 0) {
                    setSubmitStatus(-1);
                } else {
                    setListOfFaqCategories(response);
                    setIsReadOnly(false);
                }
            }).catch(function () {
            });
            getQuestionById(questionId).then((response) => {
                setState(prevState => ({
                    ...prevState,
                    question: response.question || "",
                    answer: RichTextEditor.createValueFromString(response.answer, 'html') || "",
                    status: (response.status===1)?"Active":"InActive" || "",
                    position: response.position || ""
                }))
                setIsInactive(0);
                setFormValid(false);
                var faqcategories={}
                console.log(response);
                if(response.categoryId!==null){
                    for (var key in listOfFaqCategories) {
                        if(listOfFaqCategories[key].value===response.categoryId){
                            faqcategories=listOfFaqCategories[key];
                            break;
                        } 
                    }
                    setSelectedFaqCategoryOptions(faqcategories);
                    if((faqCategoryId!==null)){
                        const currentCategoryName=faqcategories.label;
                        setCategoryName(currentCategoryName+":");
                        setIsCategory(true);
                    }
                }
            }).catch(function () {
                setSubmitStatus(-1);
            });*/
        }
      }, [questionId,faqCategoryId])

      
    const editQuestion = () => {
        setIsReadOnly(false);
        setFormValid(true);
    }
    
    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    const cancelChanges = () => {
        setIsReadOnly(true)
        setFormValid(false);
    }

    const handleOnChange = (value) => {
        setState(prevState => ({
            ...prevState,
            answer:value
        }))
        if (props.OnChange) {
            // Send the changes up to the parent component as an HTML string.
            // This is here to demonstrate using `.toString()` but in a real app it
            // would be better to avoid generating a string on each change.
            props.OnChange(
                value.toString('html')
            );
        }
    };


    /*const formValid = Object.values(state).every(v => v.length);*/
    
    const handleFaqCategoryChange = selectedFaqCategoryOptions => {
        setSelectedFaqCategoryOptions(selectedFaqCategoryOptions);
    };
    function handleSubmitClick() {
        const selectedFaqCategoryOption=(Object.keys(selectedFaqCategoryOptions).length>0)?selectedFaqCategoryOptions.value:"";
        const payload = {
            "id": questionId,
            "category_id": (faqCategoryId!=="" && faqCategoryId!==null)?faqCategoryId:selectedFaqCategoryOption,
            "question": state.question,
            "answer": state.answer.toString('html'),
            "status": (state.status==="Active")?"1":"0",
            "position": state.position
        }
        updateQuestion(payload).then(function (response) {
            setAction("UPDATEDCONTENTVIEW");
            setSubmitStatus(1);
        })
        .catch(function (error) {
            setSubmitStatus(-1);
        });
    }

    const handleSelect = (e, value) => {
        const { id, text } = value.target
        setState(prevState => ({
            ...prevState,
            [id]: text
        }))
    } 
    const backContentList = (e) => {
        if(faqCategoryId==="" || faqCategoryId===null){
            props.history.push('/faq-questions');
        }else{
            props.history.push('/faq-questions?faqCategoryId='+faqCategoryId);
        }
    }
     
    if (submitStatus === 1) {
        return (
            <div>
                <UpdateContentSuccess action={action} question={state.question} {...props}/>
            </div>
        );
    } else {
        return (
            <div className="faqs-application-submission border-opacity-25">
                <div className="title-container">
                    <Button type="submit" title={"Back"} onClick={backContentList}><i class="fa fa-arrow-left" aria-hidden="true"></i></Button>
                    <h1 className="title title-job"><label style={{ color: '#f121bd' }}><b>{categoryName}</b></label> {state.question}</h1>
                    {!isInactive && <Button type="submit" disabled={false} title={"Edit"} onClick={editQuestion}/>}
                </div>

                {submitStatus === -1 && <AlertDismissible onClose={() => setSubmitStatus(0)} alertMessage={"Something went wrong when posting your listing. Please try again."}/>}
                <div className="form-margin">
                    {   isCategory===false &&
                        <div className="form-group dropdown-margin">
                            <label for="faqCategory">Faq Category<label style={{ color: 'red' }}>*</label></label>
                            <Select isMulti={false} placeholder="Select faq category" options={listOfFaqCategories} value={selectedFaqCategoryOptions} onChange={handleFaqCategoryChange} isDisabled={isReadOnly} closeMenuOnSelect={true} />
                        </div>
                    }
                    <div className="form-group">
                        <label>
                            <label for="title">Question <label style={{ color: 'red' }}>*</label></label>
                            <BiHelpCircle data-tip="React-tooltip" data-for="title-tooltip"/>
    
                            <ReactTooltip id="title-tooltip" place="right" type="dark" effect="float">
                                <span>Please update question. if you want to edit</span>
                            </ReactTooltip>
                        </label>
                        <input  className={"form-control"} id="question" placeholder="Question" value={state.question} onChange={handleChange} disabled={isReadOnly} required/>
                    </div>
                    <div className="form-group dropdown-margin">
                        <label for="status">Status <label style={{ color: 'red' }}>*</label></label>
                        <Dropdown className="form-group employer-dropdown" onSelect={handleSelect}>
                            <Dropdown.Toggle className="bootstrap-dropdown" variant="light" id="dropdown-basic" disabled={isReadOnly}>
                                {state.status || "InActive"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item id="status">Active</Dropdown.Item>
                                <Dropdown.Item id="status">InActive</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="form-group">
                        <label>
                            <label for="answer">Answer <label style={{ color: 'red' }}>*</label></label>
                            <BiHelpCircle data-tip="React-tooltip" data-for="answer-tooltip"/>
            
                            <ReactTooltip id="answer-tooltip" place="right" type="dark" effect="float">
                                <span>answer </span>
                            </ReactTooltip>
                        </label>
                        <RichTextEditor disabled={isReadOnly} id="answer" value={state.answer} onChange={handleOnChange}
                        />
                    </div>
                        <div className="form-group">
                            <label>
                                <label for="position">Position <label style={{ color: 'red' }}>*</label></label>
                                <BiHelpCircle data-tip="React-tooltip" data-for="position-tooltip"/>
        
                                <ReactTooltip id="position-tooltip" place="right" type="dark" effect="float">
                                    <span>Please add new faq question position.</span>
                                </ReactTooltip>
                            </label>
                            <input  className={"form-control"} id="position" placeholder="Question position" value={state.position} onChange={handleChange}  required/>
                        </div>
                    <div>
                        <Button type="submit" disabled={!formValid} title={"Submit"} onClick={() => handleSubmitClick()}/>
                        {!isReadOnly && <Button type="submit" title={"Cancel"} onClick={cancelChanges}/>}
                    </div>
                </div>
            </div>
        )
    }
}

function UpdateContentSuccess({action, question, ...props}) {
    const queryParams = useLocation().search;
    const faqCategoryId = new URLSearchParams(queryParams).get('faqCategoryId'); 
    const handleFaqClick = (e) => {
        if(faqCategoryId==="" || faqCategoryId===null){
            props.history.push('/faq-questions');
        }else{
            props.history.push('/faq-questions?faqCategoryId='+faqCategoryId);
        }
    }

    if (action === "UPDATEDCONTENTVIEW") {
        return (
            <div className="general-form">
                <div className="submission-status">
                    <h1 className="title">{question} Success!</h1>
                    <br/>
                    <text className="account-state">You have successfully updated your question!</text>
                </div>
                <br/>
                <br/>
                <div className="buttons">
                    <Button type="submit" disabled={false} title={"Back to questions"} onClick={handleFaqClick}/>
                </div>
            </div>
        )
    }
}

export default withRouter(UpdateQuestion);