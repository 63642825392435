import { withRouter } from "react-router-dom";
import { useEffect, useState } from 'react';
import './Profile.css';
import { getAuthenticatedUser } from '../../services/UserService';
import { getAllListings, getListingCountByStatus } from '../../services/JobService';
import { SUPER_ADMIN } from "../../constants/userConstants";

function Profile(props) {

    const [employerName, setEmployerName] = useState();
    const [activeCount, setActiveCount] = useState();
    const [underReviewCount, setUnderReviewCount] = useState();
    const [fullName, setFullName] = useState();
    const [emailAddress, setEmailAddress] = useState();
    const [role, setRole] = useState();

    useEffect(() => {
        getAuthenticatedUser().then((response) => {
            setFullName(response.firstName + " " + response.lastName || "");
            setEmailAddress(response.userName || "");
            setRole(response.companyRole || "");
            setEmployerName(response.employer.name || "");

            if (props.role !== SUPER_ADMIN) {
                getListingCountByStatus(response.employer.id).then((counts) => {
                    setActiveCount(counts.ACTIVE || 0);
                    setUnderReviewCount(counts.PENDINGREVIEW || 0);
                })
            } else {
                getAllListings().then((response) => {
                    setActiveCount(response[0].length || 0);
                    setUnderReviewCount(response[2].length || 0);
                })
            }
        })
    
    }, [props.role]) 

    return (
        <div className="my-profile border-opacity-25">
            <div className="title-container-profile">
            {props.role === "superadmin" 
                ?
                <h1 className="title title-job">Welcome to your profile, {employerName} admin!</h1>
                :
                    <h1 className="title title-job">Welcome to your profile, {employerName} employer!</h1>}
            </div>

            <div className="section-profile">
                <div>You have <span className="data-field">{activeCount}</span> job listings posted.</div>
                {props.role === SUPER_ADMIN 
                ?
                    <div>You have <span className="data-field">{underReviewCount}</span> job listings to review.</div>
                :
                    <div>You have <span className="data-field">{underReviewCount}</span> job listings under review.</div>}
            </div>

            <div className="small-title">
                Your details
            </div>
        
            <div className="section-profile">
                <div>Full name: <span className="data-field">{fullName}</span></div>
                <div>Email address: <span className="data-field">{emailAddress}</span></div>
                <div>Role: <span className="data-field">{role}</span></div>

            </div>
        </div>
    )
}

export default withRouter(Profile);