import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Button from '../../components/Button/Button.js';
import axios from "axios";

import { EMPTY_STRING } from "../../constants/formConstants";
import { API_BASE_URL, API_URL_EXTENSION, ACCESS_TOKEN_NAME } from "../../constants/apiConstants";
import isEmpty from 'validator/es/lib/isEmpty';
import isEquals from 'validator/es/lib/equals';
import { ExportToCsv } from 'export-to-csv';
import './Report.css';

function Report(){

  const [employer, setEmployer] = useState({
    companyName: EMPTY_STRING
})
const [listOfCompanies, setListOfCompanies] = useState([]);

const [jobStat, setJobStat] = useState([])


useEffect(() => {
  const employerUrl = `${API_BASE_URL}${API_URL_EXTENSION}/employer/employerNameList`;
    axios.get(employerUrl, {'headers': {'Authorization':localStorage.getItem(ACCESS_TOKEN_NAME)}})
        .then(response => {
            setListOfCompanies(["All"].concat(response.data));
        });

  const listingUrl = `${API_BASE_URL}${API_URL_EXTENSION}/joblisting/activeJobsReport`;
    axios.get(listingUrl, {'headers': {'Authorization':localStorage.getItem(ACCESS_TOKEN_NAME)}})
        .then(response => {
            setJobStat(response.data);
        });
}, []);

const handleSelect = (e, value) => {
  const { id, text } = value.target
      setEmployer(prevState => ({
      ...prevState,
      [id]: text
  }))
} 

const exportReport = () =>{
  const data = jobStat.filter(x=>{
    if(isEmpty(employer.companyName) || isEquals("All",employer.companyName)){return true;}
    else{return isEquals(employer.companyName, x.companyName);}    
  })
  .map(x=>x);

  let companyName = "All Companies";
  if(!isEmpty(employer.companyName) && !isEquals("All",employer.companyName)){companyName = employer.companyName}

  const options = { 
    filename: `${companyName} Stats Report`,
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true, 
    showTitle: true,
    title: `JobListing Stats For ${companyName}`,
    useTextFile: false,
    useBom: true,    
    headers: ['Employer name', 'Job title', 'Website clicks', 'Favourite No','Application No.'] 
  };

  const csvExporter = new ExportToCsv(options);
 
  csvExporter.generateCsv(data);
  
}

  return(
    <div className= "d-flex flex-column page">
      <div className= "controls">
        <div className= "d-flex justify-content-around d-flex align-items-end top-control"> 
          <div className="form-group dropdown-margin report-company-name">
            <label className="label-class" for="companyName">Employer name:</label>
            <Dropdown className="form-group report-dropdown" onSelect={handleSelect}>
              <Dropdown.Toggle className="bootstrap-dropdown"  variant="light" id="dropdown-basic">
                {employer.companyName || "All"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {listOfCompanies.map((company) => <Dropdown.Item id="companyName">{company}</Dropdown.Item>)}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <span className="download-report pb-3">
                Download as: 
                <Button type="submit" disabled={false} title={"a spreadsheet"} onClick={exportReport}/>
            </span>
        </div>        
        
      </div>

      <div className= "table-div">      

      <table className="table table-striped table-hover">
        <thead className= "table-dark">
          <tr >
            <th scope="col">Employer name</th>
            <th scope="col">Job title</th>
            <th scope="col">Website clicks</th>
            <th scope="col">Favourite No.</th>
            <th scope="col">Application No.</th>
          </tr>
        </thead>
        <tbody>
          {jobStat.filter(x=>{
            if(isEmpty(employer.companyName) || isEquals("All",employer.companyName)){
              return true;
            }
            else{
                return isEquals(employer.companyName, x.companyName);
            }
            
          })
          .map((stat)=>
            <tr >
              <th scope="row">{stat.companyName}</th>
              <td>{stat.title}</td>
              <td>{stat.clickWebsiteCounter}</td>
              <td>{stat.favoriteCounter}</td>
              <td>{stat.appliedCounter}</td>
          </tr>
          )}
          
          
        </tbody>
      </table>
      </div>     
      
    </div>
  )
}

export default withRouter(Report);



