import React, { useState, useEffect } from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import ReactTooltip from 'react-tooltip';
import { BiHelpCircle } from 'react-icons/bi';
import Button from '../../components/Button/Button.js';
import AlertDismissible from '../../components/AlertDismissible/AlertDismissible.js';
import { postEmployerSignUp } from '../../services/LoginService';
import { getEmployerNames } from '../../services/EmployerService.jsx';
import { SELECT_ONE, CANT_FIND_COMPANY, EMPTY_STRING } from "../../constants/formConstants";

const emailRegex = /[a-z]+(\.|_)?([a-z0-9]+(\.|_)?)+?[a-z0-9]+@[a-z0-9]+_?[a-z0-9]+\.[a-z]+/;

function Form(props) {
    const [isNewCompany, setIsNewCompany] = useState();

    const [state, setState] = useState({
        companyName: EMPTY_STRING,
        companyRole: EMPTY_STRING,
        firstName: EMPTY_STRING,
        lastName: EMPTY_STRING,
        email: EMPTY_STRING,
        password: EMPTY_STRING,
    })

    const [selectedCompany, setSelectedCompany] = useState(SELECT_ONE);
    const [listOfCompanies, setListOfCompanies] = useState([]);

    useEffect(() => {
        getEmployerNames().then((response) => setListOfCompanies(response))
    }, [])

    const maxChar = 200;
    const [charsLeft, setCharsLeft] = useState(200);

    const formFilled = Object.values(state).every(v => v.length);
    const emailValid = emailRegex.test(state.email);
    const formValid = formFilled && emailValid;

    const handleSelect = (e, value) => {
        const companyName = value.target.text
        setIsNewCompany(companyName === CANT_FIND_COMPANY);
        setSelectedCompany(companyName);

        setState(companyName === CANT_FIND_COMPANY
            ? prevState => ({
                ...prevState,
                companyName: EMPTY_STRING,
                website: EMPTY_STRING,
                aboutCompany: EMPTY_STRING
            })
            : ({website, aboutCompany, ...restPrevState}) => ({
                ...restPrevState,
                companyName
            })
        );
    }

    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))   
    }

    const wordCount = (e) => {
        let currentText = e.target.value.length;
        setCharsLeft(maxChar-currentText);
    }

    const handleSubmitClick = (e) => {
        e.preventDefault();
        /*const company = listOfCompanies.find(element =>
            element.name === selectedCompany
        );*/
        postEmployerSignUp(state.companyName, state.website, state.aboutCompany, state.companyRole,
            state.firstName, state.lastName, state.email, state.password).then(function (response) {
                if (response.status === 403) {
                    throw new Error("HTTP status " + response.status);
                }
            props.onFormSubmit(1);
        }).catch(function (error) {
            if (error.response.status === 403) {
                props.onFormSubmit(-2);
            } else {
                props.onFormSubmit(-1);
            }
        });
    }

    const redirectToLogin = (e) => {
        e.preventDefault()
        props.history.push('/login');
    }

    return (
        <div className="field-form">
            <h1 className="title">Employer Sign Up</h1>
            {props.status === -1 && <AlertDismissible onClose={() => props.onFormSubmit(0)} alertMessage={"Something went wrong when creating your account. Please try again."}/>}
            {props.status === -2 && <AlertDismissible onClose={() => props.onFormSubmit(0)} alertMessage={<>
                <div>There are the maximum number of 5 existing users registered to this account.</div>
                <div>Please create a new account or contact enterprise.team@nas.org.uk for support</div>
            </>}/>}
            <form>
                <div className="form-group">
                    <label for="companyName">Company name</label>
                    <Dropdown className="form-group employer-dropdown" onSelect={handleSelect}>
                        <Dropdown.Toggle className="bootstrap-dropdown" variant="light" id="dropdown-basic">
                            {selectedCompany || "Company name"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item>I can't find my company</Dropdown.Item>
                           {listOfCompanies.map((company) => <Dropdown.Item>{company}</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                {isNewCompany && <div classname="form-group">
                    <input className={"form-control company-name"} id="companyName" placeholder="Company name" value={state.companyName} onChange={handleChange}/>
                </div>}
                {isNewCompany && <div className="form-group">
                    <label for="website">Website</label>
                    <input className="form-control" id="website" placeholder="Website" value={state.website} onChange={handleChange}/>
                </div>}
                <div className="form-group">
                    <label for="companyRole">Company role</label>
                    <input className={"form-control"} id="companyRole" placeholder="Company role" value={state.companyRole} onChange={handleChange}/>
                </div>
                <div className="form-group">
                    <label for="firstName">First name</label>
                    <input className={"form-control"} id="firstName" placeholder="First name" value={state.firstName} onChange={handleChange}/>
                </div>
                <div className="form-group">
                    <label for="lastName">Last name</label>
                    <input className={"form-control"} id="lastName" placeholder="Last name" value={state.lastName} onChange={handleChange}/>
                </div>
                <div className="form-group">
                    <label for="email">Email address</label>
                    <input className={"form-control" + ((formFilled && !emailValid) ? " is-invalid" : "")} id="email" placeholder="Email address" value={state.email} onChange={handleChange}/>
                </div>
                <div className="form-group">
                    <label for="password">Password</label>
                    <input className={"form-control"} type="password" id="password" placeholder="Password" value={state.password} onChange={handleChange}/>
                </div>
            </form>
            {isNewCompany && <div className="form-group">
                    <label for="aboutCompany">About your company </label>
                    <BiHelpCircle data-tip="React-tooltip" data-for="aboutCompany-tooltip"/>
                    <ReactTooltip id="aboutCompany-tooltip" place="right" type="dark" effect="float">
                        <span>
                            Focus on any autism positive/friendly practices here: e.g., ‘we encourage diverse candidates to apply and invite you to talk to us about reasonable adjustments that we may be able make for you’.
                        </span>
                    </ReactTooltip>
                    <textarea maxLength={200} cols="50" rows="2" className="form-control" id="aboutCompany" placeholder="About your company" value={state.aboutCompany} onChange={e => {handleChange(e); wordCount(e)}}/>
                    <p className="simple-text">Characters left: {charsLeft}</p>
                </div>
            }
            <div>
                <Button type={"submit"} disabled={!formValid} title={"Create account"} onClick={handleSubmitClick} />
            </div>
            <div className="simple-text">
                Already have an account? <a href="/login" onClick={redirectToLogin}>Log in now</a>
            </div>
        </div>
    )
}  

export default Form;