import React from 'react';

function NewUser(props) {

    const userUrl = `user?employer=${props.companyName}&id=${props.id}`;

    const handleClick = (e) => {
        e.preventDefault();
        props.history.push(userUrl);
    }

    return (
        <a className="click-job" href={userUrl} onClick={handleClick}>
            <div className="job">
                <div>
                    {props.companyName}
                </div>
                <div className="job-bottom">
                    <div>
                        {props.name}
                    </div>
                    <div>
                        {props.companyRole}
                    </div>
                </div>
            </div>
        </a>
    )
}

export default NewUser;