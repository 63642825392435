import React from 'react';
import Button from '../../components/Button/Button.js';

function SubmitStatus(props) {

    const redirectToDashboard = (e) => {
        e.preventDefault()
        props.history.push('/my-dashboard');
    }

    return (
        <div className="submission-status">
            <h1 className="title">Congratulations!</h1>
            <br/>
            <div className="account-state">This account has been created successfully!</div>
            <div className="account-state">An email will be sent to the email address indicated in the form with instructions on how to reset the default password and login.</div>
            <div>
                <br/>
                <Button type={"submit"} title={"Dashboard"} onClick={redirectToDashboard} />
            </div>
        </div>
    )
}

export default SubmitStatus;