import React, { useState, useEffect } from 'react';
import './App.css';
import Routes from '../../components/Routes/Routes';
import NavBar from '../../components/Navbar/NavBar';
import AlertComponent from '../../components/AlertComponent/AlertComponent';
import { getAuthenticatedUser } from '../../services/UserService';
import Spinner from "react-bootstrap/Spinner";

import {
  HashRouter as Router,
  Switch,
} from "react-router-dom";

function App() {
  
  const [errorMessage, updateErrorMessage] = useState(null);
  const [{role, employerId}, setUser] = useState({});

  /*=== change whole app color ===*/
  const [isToggled, toggle] = useState(true)
  const [activeLabel,setActiveLabel] = useState("Light")

  const changeAppColor = (e) => {
    const white = 'rgb(255, 255, 255)';
    const offwhite = 'rgb(245, 245, 245)';
    let el = document.body;
    const wholeBgColor=(el.style.backgroundColor === offwhite)?white:offwhite;
    el.style.backgroundColor = wholeBgColor;
    (e.target.checked === true)?setActiveLabel("Light"):setActiveLabel("Dark");
    toggle(!isToggled);
  }

  useEffect(() => {
      getAuthenticatedUser().then((response) => {
        setUser({role: response.access, employerId: response.employer?.id})
    })
  }, [])

  return (
    <Router>
      {role
        ? <div>
          <NavBar role={role} />
          <div className="changeWholeColorButton">
            <div className="toggle-switch">
              <input type="checkbox" className="checkbox" name={activeLabel} id={activeLabel}  defaultChecked={isToggled} onClick={changeAppColor}/>
              <label className="label {activeLabel}" htmlFor={activeLabel}>
                <span className="inner" />
                <span className="switch" />
              </label>
            </div>
          </div>
          
          <div className="App">
            <div className="container d-flex align-items-center flex-column">
              <Switch>
                {Routes({role, employerId, setUser})}
              </Switch>
              <AlertComponent errorMessage={errorMessage} hideError={updateErrorMessage} />
            </div>
          </div>
        </div>
        :
        <div className="spinner-container">
          <Spinner animation="border" role="status" />
        </div>
      }
    </Router>
  );
}

export default App;