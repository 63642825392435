import React, { useState,useEffect } from 'react';
import Button from '../../components/Button/Button.js';
import AlertDismissible from '../../components/AlertDismissible/AlertDismissible.js';
import {createQuestion,getAllActiveCategories,getCategoryById} from '../../services/FaqsService';
import './Faqs.css';
import { withRouter,useLocation } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { BiHelpCircle } from 'react-icons/bi';
import Dropdown from "react-bootstrap/Dropdown";
import { EMPTY_STRING } from "../../constants/formConstants";
import RichTextEditor from 'react-rte';
import { SUPER_ADMIN } from '../../constants/userConstants';
import Select from 'react-select';

function CreateQuestion(props) {

    const [submitStatus, setSubmitStatus] = useState(0);
    const [isCategory, setIsCategory] = useState(false);
    const [categoryName, setCategoryName] = useState([]);
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [listOfFaqCategories, setListOfFaqCategories] = useState([]);
    const [selectedFaqCategoryOptions,setSelectedFaqCategoryOptions]= useState("");  
    const [state, setState] = useState({
        question: EMPTY_STRING,
        answer: RichTextEditor.createEmptyValue(),
        categoryId: EMPTY_STRING,
        status: EMPTY_STRING,
        position:EMPTY_STRING
    });
    const queryParams = useLocation().search;
    const faqCategoryId = new URLSearchParams(queryParams).get('faqCategoryId'); 

    useEffect(() => {
        if (props.role === SUPER_ADMIN) {
            if(faqCategoryId==="" || faqCategoryId===null){
                getAllActiveCategories().then((response) => {
                    if (response.length === 0) {
                        setSubmitStatus(-1);
                    } else {
                        setListOfFaqCategories(response);
                        setIsReadOnly(false);
                    }
                }).catch(function () {
                    setSubmitStatus(-1);
                });
            }else{
                getCategoryById(faqCategoryId).then((response) => {
                    setCategoryName("For "+response.name);
                }).catch(function () {
                    setSubmitStatus(-1);
                });
                setIsCategory(true);
            }
        }
    }, [props.role,faqCategoryId]);

    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))        
    }
    
    const handleSelect = (e, value) => {
        const { id, text } = value.target
        setState(prevState => ({
            ...prevState,
            [id]: text
        }))
    }

    const handleOnChange = (value) => {
        setState(prevState => ({
            ...prevState,
            answer:value
        }))
        if (props.OnChange) {
            // Send the changes up to the parent component as an HTML string.
            // This is here to demonstrate using `.toString()` but in a real app it
            // would be better to avoid generating a string on each change.
            props.OnChange(
                value.toString('html')
            );
        }
    };
    var checkFormValid=true;
    Object.entries(state).forEach(entry => {
        const [key, value] = entry;
        var array = ["answer","status","categoryId"];
        if(!array.includes(key) && (value==='')){
            checkFormValid=false;
        }
    });
    
    const cancelChanges = (e) => {
        if(faqCategoryId==="" || faqCategoryId===null){
            props.history.push('/faq-questions');
        }else{
            props.history.push('/faq-questions?faqCategoryId='+faqCategoryId);
        }
    }
    const formValid=(checkFormValid===true)?true:checkFormValid;
    const handleFaqCategoryChange = selectedFaqCategoryOptions => {
        setSelectedFaqCategoryOptions(selectedFaqCategoryOptions);
    };
    const handleSubmitClick = (e) => {
        e.preventDefault();
        const selectedFaqCategoryOption=(Object.keys(selectedFaqCategoryOptions).length>0)?selectedFaqCategoryOptions.value:"";
        const payload = {
            "question": state.question,
            "category_id": (faqCategoryId!=="" && faqCategoryId!==null)?faqCategoryId:selectedFaqCategoryOption,
            "answer": state.answer.toString('html'),
            "status": ((state.status==="Active")||(state.status===""))?"1":"0",
            "position": state.position
        }
        createQuestion(payload).then(function (response) {
            //props.onFormSubmit(1);
            setSubmitStatus(1);
        }).catch(function (error) {
            //props.onFormSubmit(-1);
        });
    }
    if (submitStatus === 1) {
        return (
            <div>
                <SubmitStatus {...props}/>
            </div>
        );
    } else {
        return (
            <div className="general-form create-question-form border-opacity-25">
                <div className="field-form">
                    <h1 className="title">Adding New Question {categoryName}</h1>
                    {props.status === -1 && <AlertDismissible onClose={() => props.onFormSubmit(0)} alertMessage={"Something went wrong when adding this question. Please try again."}/>}
                    <div className="form-margin">
                        {   isCategory===false &&
                            <div className="form-group dropdown-margin">
                                <label for="faqCategory">Faq Category<label style={{ color: 'red' }}>*</label></label>
                                <Select isMulti={false} placeholder="Select faq category" options={listOfFaqCategories} value={selectedFaqCategoryOptions} onChange={handleFaqCategoryChange} isDisabled={isReadOnly} closeMenuOnSelect={true} />
                            </div>
                        }
                        <div className="form-group">
                            <label>
                                <label for="title">Question <label style={{ color: 'red' }}>*</label></label>
                                <BiHelpCircle data-tip="React-tooltip" data-for="title-tooltip"/>
        
                                <ReactTooltip id="title-tooltip" place="right" type="dark" effect="float">
                                    <span>Please add new faq question.</span>
                                </ReactTooltip>
                            </label>
                            <input  className={"form-control"} id="question" placeholder="Question" value={state.question} onChange={handleChange}  required/>
                        </div>
                        <div className="form-group dropdown-margin">
                            <label for="status">Status <label style={{ color: 'red' }}>*</label></label>
                            <Dropdown className="form-group faq-question employer-dropdown" onSelect={handleSelect}>
                                <Dropdown.Toggle className="bootstrap-dropdown" variant="light" id="dropdown-basic" >
                                    {state.status || "Active"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item id="status">Active</Dropdown.Item>
                                    <Dropdown.Item id="status">InActive</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="form-group">
                            <label>
                                <label for="answer">Answer <label style={{ color: 'red' }}>*</label></label>
                                <BiHelpCircle data-tip="React-tooltip" data-for="answer-tooltip"/>
                
                                <ReactTooltip id="answer-tooltip" place="right" type="dark" effect="float">
                                    <span>Please add answer </span>
                                </ReactTooltip>
                            </label>
                            <RichTextEditor  id="answer" value={state.answer} onChange={handleOnChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>
                                <label for="position">Position <label style={{ color: 'red' }}>*</label></label>
                                <BiHelpCircle data-tip="React-tooltip" data-for="position-tooltip"/>
        
                                <ReactTooltip id="position-tooltip" place="right" type="dark" effect="float">
                                    <span>Please add new faq question position.</span>
                                </ReactTooltip>
                            </label>
                            <input  className={"form-control"} id="position" placeholder="Question position" value={state.position} onChange={handleChange}  required/>
                        </div>
                        <div>
                            <Button type="submit" disabled={!formValid} title={"Submit"} onClick={handleSubmitClick}/>
                            <Button type="submit" title={"Cancel"} onClick={cancelChanges}/>
                        </div>
                    </div>                
                </div>
            </div>
        )  
    }
}

function SubmitStatus(props) {

    const queryParams = useLocation().search;
    const faqCategoryId = new URLSearchParams(queryParams).get('faqCategoryId'); 
    const redirectToFaqs = (e) => {
        e.preventDefault();        
        if(faqCategoryId==="" || faqCategoryId===null){
            props.history.push('/faq-questions');
        }else{
            props.history.push('/faq-questions?faqCategoryId='+faqCategoryId);
        }
    }

    return (
        <div className="submission-status">
            <h1 className="title">Congratulations!</h1>
            <br/>
            <div className="account-state">You have successfully added a new question!</div>
            <div>
                <br/>
                <Button type={"submit"} title={"Back"} onClick={redirectToFaqs} />
            </div>
        </div>
    )
}

export default withRouter(CreateQuestion);