import React from 'react';
import NewUser from "./NewUser";
import { Scrollbars } from 'react-custom-scrollbars';

function UsersToLoad(props) {

    return (
        <div>
            <h2 className="column-title">{props.status}</h2>
            <div className="job-list">
                <Scrollbars style={{ height: 300 }}>
                    {props.users.map((user) => 
                        <div className="job-container">
                            <NewUser 
                            companyName={user.companyName} 
                            name={user.contactFirstName + " " + user.contactLastName} 
                            companyRole={user.companyRole}
                            id={user.id}
                            {...props}/>
                        </div>)}
                        {props.users.length === 0 &&
                        <div style={{marginLeft: '1em', marginTop: '1em'}}>No users to display</div>}
                </Scrollbars>
            </div>
        </div>
    )
}  

export default UsersToLoad;