import React, { useState } from 'react';
import './LoginForm.css';
import { postLogin } from '../../services/LoginService'
import { withRouter } from "react-router-dom";
import Button from '../../components/Button/Button.js';
import AlertDismissible from '../../components/AlertDismissible/AlertDismissible.js';
import { EMPTY_STRING } from "../../constants/formConstants";
import { ACCESS_TOKEN_NAME } from "../../constants/apiConstants";

const emailRegex = /[a-z]+(\.|_)?([a-z0-9]+(\.|_)?)+?[a-z0-9]+@[a-z0-9]+_?[a-z0-9]+\.[a-z]+/;

function LoginForm(props) {
    const [state, setState] = useState({
        email: EMPTY_STRING,
        password: EMPTY_STRING,
    })

    const [successStatus, setSuccessStatus] = useState(null);

    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    const formFilled = Object.values(state).every(v => v.length);
    const emailValid = emailRegex.test(state.email);
    const formValid = formFilled && emailValid;

    const handleSubmitClick = (e) => {
        e.preventDefault();

        postLogin(state.email, state.password).then(({jwttoken, access, employerId}) => {
            setSuccessStatus(true);
            localStorage.setItem(ACCESS_TOKEN_NAME, jwttoken);
            props.setUser({role: access, employerId});
        }).catch(() => setSuccessStatus(false));
    }

    const redirectToRegister = (e) => {
        e.preventDefault()
        props.history.push('/sign-up');
    }

    const redirectToForgotPassword = (e) => {
        e.preventDefault()
        props.history.push('/forgot-password');
    }

    return (
        <div className="general-form">
            <div className="field-form">
                <h1 className="title">Login</h1>
                {successStatus === false && <AlertDismissible onClose={() => setSuccessStatus(true)} alertMessage={"Something went wrong when logging in. Please try again."} />}
                <form>
                    <div className="form-group text-left">
                        <label htmlFor="exampleInputEmail1">Email address</label>
                        <input type="email"
                            className={"form-control" + ((formFilled && !emailValid) ? " is-invalid" : "")}
                            id="email"
                            aria-describedby="emailHelp"
                            placeholder="Enter email"
                            value={state.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "baseline"}}>
                        <label htmlFor="exampleInputPassword1">Password</label>
                        <small className="right link"><a href="/forgot-password" onClick={redirectToForgotPassword}>Forgot Password?</a></small>
                        </div>
                        <input type="password"
                            className="form-control"
                            id="password"
                            placeholder="Password"
                            value={state.password}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-check">
                    </div>
                    <Button type={"submit"} disabled={!formValid} title={"Log In"} onClick={handleSubmitClick} />
                </form>
            </div>
            <div className="simple-text">
                Don't have an account? <a href="/sign-up" className="loginText link" onClick={redirectToRegister}>Sign up now</a>
            </div>
        </div>
    )
}

export default withRouter(LoginForm);