import axios from "axios";

import {
  API_BASE_URL,
  API_URL_EXTENSION,
  ACCESS_TOKEN_NAME,
} from "../constants/apiConstants";

function getUsersByEmployer(employerId) {
  const url = API_BASE_URL + API_URL_EXTENSION + "/employer/employerUsers?employerId=" + employerId;

  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    });
}

function deleteEmployer(employerId) {
  const url = API_BASE_URL + API_URL_EXTENSION + "/employer/delete?employerId=" + employerId;

  return axios
    .delete(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return true;
    })
    .catch(function (error) {
      return false;
    });
}

function getEmployerNames() {
  const url = API_BASE_URL + API_URL_EXTENSION + "/employer/employerNameList";

  return axios
    .get(url, {
      headers: { Authorization: localStorage.getItem(ACCESS_TOKEN_NAME) },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    });
}

export { deleteEmployer, getUsersByEmployer, getEmployerNames };
